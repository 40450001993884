export interface SolarFinanceRequest {
  fullName: string;
  phoneNumber: string;
  email: string;
  accessAcount: string;
  closestBranch: string;
  workType: number | null;
  monthlyIncome: number;
  currency: number | null;
}

export interface SolarFinanceResponse {
  succeeded: boolean;
  message: string;
  errors: null;
  data: any;
  meta: any;
  code: number;
  pageMeta: any;
}

export enum Currencies {
  "Nigerian Naira" = 1,
  "United States Dollar" = 2,
  "EURO" = 3,
  "British Pound Sterling" = 4,
  "Japanese Yen" = 5,
  "Canadian Yen" = 6,
}

export const CurrencyArray = [
  "Nigerian Naira",
  "United States Dollar",
  "EURO",
  "British Pound Sterling",
  "Japanese Yen",
  "Canadian Dollar",
];

export enum WorkTypes {
    "Salary Earner" = 1,
    "Business Earner" = 2,
};

export const WorkTypeArray = ['Salary Earner', 'Business Earner']
