import dayjs, { Dayjs } from "dayjs";
import { DropdownOption } from "../components/FormElements/Dropdown";
import jwt, { Jwt, JwtPayload } from "jsonwebtoken";
import { AxiosError } from "axios";
import { ExpectedJSONFromExcel } from "../views/Insurance/HomeOwners/HomeOwnersForm/GetAQuote";
import { getQuoteHoWInsuranceDataExcel } from "../types/Inspection";

export function toDateString(dateInput: Date) {
  let month = new Date(dateInput).getMonth() + 1;
  let date = new Date(dateInput).getDate();
  let year = new Date(dateInput).getFullYear();
  let formatedDate = `${date}/${month}/${year}`;

  return formatedDate;
}

export function toDateStringRestriction(dateInput: Date) {
  let month = dateInput.getMonth() + 1;
  let date = dateInput.getDate();
  let dateString = date < 10 ? `0${date}` : date.toString();
  let monthString = month < 10 ? `0${month}` : month.toString();
  let year = dateInput.getFullYear();
  let formatedDate = `${year}-${monthString}-${dateString}`;

  return formatedDate;
}

export function combineDateTime(time: Dayjs | null, date: Date) {
  let year = date.getFullYear();
  let month = date.getMonth();
  let day = date.getDate();

  // Extract hours, minutes, and seconds from the time object
  let hours = dayjs(time).hour();
  let minutes = dayjs(time).minute();
  let seconds = dayjs(time).second();

  // Increase hours by one
  hours += 1;

  // Create a new Date object by combining date and time information
  let newDateTimeObject = new Date(year, month, day, hours, minutes, seconds);
  return newDateTimeObject;
}

export function toScheduleDateString(dateInput: Date) {
  const months = [
    "Jan",
    "Feb",
    "Mar",
    "April",
    "May",
    "June",
    "July",
    "Aug",
    "Sept",
    "Oct",
    "Nov",
    "Dec",
  ];

  let month = months[dateInput.getMonth()];
  let date = dateInput.getDate();
  let year = dateInput.getFullYear();
  let formatedDate = `${date} ${month} ${year}`;

  return formatedDate;
}

export function toDay(dateInput: Date) {
  const days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  let day = days[dateInput.getDay()];

  return day;
}

export function* generateId() {
  let id = 1;

  while (true) {
    yield id;
    id++;
  }
}

export function enumToDropdownObj(e: any) {
  let dropdownObj: DropdownOption[] = Object.keys(e).map((key) => {
    return {
      value: key,
      label: e[key],
    };
  });

  return dropdownObj;
}

// export function hasWallet(token: string) {
//     let decodedToken: Jwt | null = jwt.decode(token, { complete: true });

//     // First check the local storage
//     if (decodedToken){
//         let wallet_id = localStorage.getItem('homezone_wallet');

//         if (decodedToken?.payload.wallet_id) {
//             return true;
//         }
//         else
//         if (wallet_id) {
//             return true
//         }
//     }

//     return false;
// }

// export function getWalletId(token: string): string {
//     let decodedToken: Jwt | null = jwt.decode(token, { complete: true });

//     let wallet_id = localStorage.getItem('homezone_wallet');

//     return decodedToken?.payload.wallet_id || wallet_id || '';
// }

// export function getUserEmail(token: string): string {
//     let decodedToken: Jwt | null = jwt.decode(token, { complete: true });

//     return decodedToken?.payload.email || '';
// }

export function hasWallet(token: string) {
  let decodedToken: Jwt | null = jwt.decode(token, { complete: true });

  // First check the local storage
  if (decodedToken) {
    let wallet_id = localStorage.getItem("homezone_wallet");

    if ((decodedToken?.payload as JwtPayload)?.wallet_id) {
      return true;
    } else if (wallet_id) {
      return true;
    }
  }

  return false;
}

export function getWalletId(token: string): string {
  let decodedToken: Jwt | null = jwt.decode(token, { complete: true });

  let wallet_id = localStorage.getItem("homezone_wallet");

  return (decodedToken?.payload as JwtPayload)?.wallet_id || wallet_id || "";
}

export function getUserEmail(token: string): string {
  let decodedToken: Jwt | null = jwt.decode(token, { complete: true });

  return (decodedToken?.payload as JwtPayload)?.email || "";
}

export function getUserId(token: string): string {
  let decodedToken: Jwt | null = jwt.decode(token, { complete: true });

  return (decodedToken?.payload as JwtPayload)?.id || "";
}

export const formatNumberToCurrency = (inputAmount: number | string) => {
  typeof inputAmount === "number"
    ? (inputAmount = inputAmount.toString())
    : (inputAmount = inputAmount);
  return Number(inputAmount.replace(/,/g, "")).toLocaleString();
};

export const customModalStyles = {
  content: {
    top: "70%",
    left: "50%",
    right: "auto",
    bottom: "auto",
    marginRight: "-50%",
    transform: "translate(-50%, -50%)",
    backgroundColor: "white",
    zIndex: 9999,
    boxShadow: "0px 5px 10px 0px rgba(0, 0, 0, 0.5)",
  },
};

export function formatAmount(
  number: number,
  decimals: number = 2,
  locale: string = "en-NG"
): string {
  // Check if the number is a valid finite number
  if (!isFinite(number)) {
    throw new Error("Invalid number");
  }

  // Use toFixed to set the number of decimals
  const formattedNumber = number.toFixed(decimals);

  // Use toLocaleString to add commas for thousands separator
  const formattedAmount = parseFloat(formattedNumber).toLocaleString(locale);

  return formattedAmount;
}

export const genderArray = ["Male", "Female", "Other"];

export const religionArray = ["Christianity", "Islam", "Others"];

export const maritalStatusArray = ["Single", "Married", "Other"];

export const sectorArray = [
  "Agriculture",
  "Arts",
  "Financial Services",
  "Sciences",
  "Technology",
];

export const premiumPaymentFreqArr = [
  "Single Payment Frequency",
  "Annual Payment Frequency",
];

export const returnMsgFromAxiosError = (
  error: AxiosError<any>,
  fallBack: string
) => {
  if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.message
  ) {
    return error.response.data.message;
  } else if (
    error &&
    error.response &&
    error.response.data &&
    error.response.data.Message
  ) {
    return error.response.data.Message;
  } else if (error.message) {
    return error.message;
  } else {
    return fallBack;
  }
};

const convertSingleFileToBlob = async (file: File) => {
  let base64string: string = "";
  const reader = new FileReader();
  reader.readAsDataURL(file);
  reader.onload = () => {
    const base64String: any = reader.result;
    base64string = base64String;
  };

  const imageBlob = await fetch(base64string).then((response) =>
    response.blob()
  );

  return imageBlob;
};

export const convertFilesToBlobs = async (file: File | File[]) => {
  const blobArr: Blob[] = [];
  if (Array.isArray(file)) {
    for (let i = 0; i < file.length; i++) {
      const imgBlob = await convertSingleFileToBlob(file[i]);
      blobArr.push(imgBlob);
    }
    return blobArr;
  }
  const imageBlob = await convertSingleFileToBlob(file);
  blobArr.push(imageBlob);
  return blobArr;
};

export const returnRandomStr = () => {
  return (1000 * Math.random()).toString();
};

export function containsNumber(str: string) {
  return /\d/.test(str);
}

export function convertKeysToLowerCase(
  data: getQuoteHoWInsuranceDataExcel[]
): ExpectedJSONFromExcel[] {
  //@ts-ignore
  return data.map((obj: any) => {
    const lowerCaseObj = Object.fromEntries(
      Object.entries(obj).map(([key, value]) => [key.toLowerCase(), value])
    );
    return lowerCaseObj;
  });
}

export const changeBlobsToFile = (blobs: Blob[], keys: string[]): File[] => {
  const files = blobs.map((blob, index) => {
    return new File([blob], keys[index]);
  });

  return files;
};


export function capitalizeFirstLetter(string: string) {
  if (!string) return string; // Handle empty string or undefined input
  return `${string.charAt(0).toUpperCase()}${string.slice(1)}`;
}