import axios from "axios";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const baseUrl =
  process.env.REACT_APP_BASE_URL || "https://api-sandbox.homebuddy.ng";

const token = localStorage.getItem('token')

const api = axios.create({
  baseURL: baseUrl,
  timeout: 60000,
});

// Function to show a session expired toast
const showSessionExpiredToast = (err: string) => {
  toast.error(err, {
    position: toast.POSITION.TOP_LEFT,
    autoClose: 4000, // Close the toast after 5 seconds
    hideProgressBar: true,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });
};

api.interceptors.response.use(
  (response) => {
    return response;
  },
  (error) => {
    const requestConfig = error.config;
    // exempt some requests from 401 redirect
    if (requestConfig.headers.ExemptedRequest === "true") {
      return;
    }

    if (error.response && error.response.status === 401) {
      showSessionExpiredToast("Session expired. Please sign in.");
      setTimeout(() => {
        window.location.href = "/signin";
      }, 4000);
      return;
    }

    if (error.code === "ECONNABORTED" && token) {
      showSessionExpiredToast("Request timed out. Please try again later.");
      return Promise.reject(
        new Error("Request timed out. Please try again later.")
      );
    }

    return Promise.reject(error);
  }
);

export default api;
