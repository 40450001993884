import React from 'react';
import { Property } from '../../../types/Property';
import Dropdown from '../../FormElements/Dropdown';
import TextInput, { TextInputProps } from '../../FormElements/TextInput';
import { ViewProps } from '../Amenities/index.old';
import styles from './location.module.scss';
import { States } from '../../../types/states';
import { Autocomplete, useJsApiLoader } from '@react-google-maps/api';

export interface LocationErrorState{
    address: boolean; country: boolean;
    state: boolean; city: boolean;
}

export interface LoactionProps extends ViewProps{
    errorState?: LocationErrorState;
    unhighlight?: (e: React.ChangeEvent<any>) => void;
}

// Libraries for google script
const libraries: ("drawing" | "geometry" | "localContext" | "places" | "visualization")[] = ["places"];

const Location: React.FC<LoactionProps> = ({ 
    onPrevStep, onNextStep, prevKey, nextKey,
    errorState, unhighlight 
})=>{

    // Hooks
    const { isLoaded } = useJsApiLoader({
        googleMapsApiKey: process.env.REACT_APP_GOOGLE_MAPS_API_KEY || '',
        libraries: libraries
    })

    // State
    const [property, setProperty] = React.useState<Property|undefined>();
    const [autocomplete, setAutoComplete] = React.useState<google.maps.places.Autocomplete>();
    const [cityAutocomplete, setCityAutoComplete] = React.useState<google.maps.places.Autocomplete>();
    const [latitude, setLatitude] = React.useState<string|undefined>(property?.latitude?.toString());
    const [longitude, setLongitude] = React.useState<string|undefined>(property?.longitude?.toString());
    const [address, setAddress] = React.useState<string|undefined>(property?.address)
    const [city, setCity] = React.useState<string|undefined>(property?.city)


    // Listeners
    const subscribe = ()=>{
        document.addEventListener('updateProduct', (event: CustomEventInit)=>{
            setProperty(event.detail.value);
            setLatitude(event.detail.value?.latitude?.toString());
            setLongitude(event.detail.value?.longitude?.toString());
            setAddress(event.detail.value?.address);
        })
    }
    const unsubscribe = ()=>{
        document.removeEventListener('updateProduct', (event: CustomEventInit)=>{
            setProperty(event.detail.value);
            setLatitude(event.detail.value?.latitude?.toString());
            setLongitude(event.detail.value?.longitude?.toString());
            setAddress(event.detail.value?.address);
        })
    }

    const onLoad =  (autocomplete: google.maps.places.Autocomplete)=> {
        setAutoComplete(autocomplete);
    }

    const onCityLoad =  (autocomplete: google.maps.places.Autocomplete)=> {
        setCityAutoComplete(autocomplete);
    }
    
    const onPlaceChanged = ()=> {
        if (autocomplete) {
          setAddress(autocomplete.getPlace().formatted_address);
          setLatitude(autocomplete.getPlace().geometry?.location?.lat().toString());
          setLongitude(autocomplete.getPlace().geometry?.location?.lng().toString());
        } else {
        }
    }

    const onCityChanged = ()=> {
        if (cityAutocomplete) {
          setCity(cityAutocomplete.getPlace().formatted_address);
        } else {
        }
    }

    // Effect for update mode
    React.useEffect(()=>{
        subscribe();
        return unsubscribe();
    }, [property]);

    const handlePrevStep = ()=>{
        if (onPrevStep && prevKey) {
            onPrevStep(prevKey)
        }
    }
    const handleNextStep = ()=>{
        if (onNextStep && nextKey) {
            onNextStep(nextKey)
        }
    }

    interface LocationInput extends TextInputProps{ 
        custom?: boolean, value?: any
        type?: "city" | "state", 
    }
    let locationInputs: LocationInput[] = [
        // { 
        //     placeholder: "*Address", name: "address", value: address, 
        //     error: errorState?.address, required: errorState?.address,
        //     onChange: unhighlight  
        // },
        { 
            placeholder: "Country", name: "country", value: "Nigeria", custom: true  
        },
        { 
            placeholder: "*State", name: "state", value: property?.state, type: 'state',
            required: true  
        },
        { 
            placeholder: "*City", name: "city", value: property?.city, type: 'city',
            required: true  
        },
        //{ placeholder: "Neighborhood", name: "neighbourhood", value: property?.neighbourhood  },
        //{ placeholder: "Zip", name: "zip", value: property?.zip },
    ];
    let mapInputs: LocationInput[] =[
        { 
            placeholder: "Latitude (for Google Maps)", name: "latitude", value: latitude,
            disabled: true
        },
        { 
            placeholder: "Longitude (for Google Maps)", name: "longitude", value: longitude,
            disabled: true 
        },
    ]

    return(
        <div className="ltn__product-tab-content-inner">

            {/** Listing Location */}
            <h6>Listing Location</h6>
            <div className="row">
                <div className="col-md-6">
                   {
                       isLoaded ?
                       <Autocomplete
                            className={styles.auto}
                            onLoad={onLoad}
                            onPlaceChanged={onPlaceChanged}
                        >
                        <TextInput placeholder="*Address" name="address"
                            useValue={address}
                            error={errorState?.address}      
                            required={errorState?.address}
                            onChange={unhighlight}
                        />
                        </Autocomplete>
                        :
                        "...Loading"
                   }
                </div>
                {
                    locationInputs.map((item, index)=>{
                        if (item.type === 'city') {
                            return(
                                <div className="col-md-6" key={`loc${index}`}>
                                    {
                                        isLoaded ?
                                        <Autocomplete
                                            className={styles.auto}
                                            onLoad={onCityLoad}
                                            onPlaceChanged={onCityChanged}
                                            options={{
                                                types: ['(cities)'],
                                                componentRestrictions: { country: "ng" }
                                            }}
                                            //onPlaceChanged={onPlaceChanged}
                                        >
                                            <TextInput placeholder={item.placeholder} 
                                                name={item.type}
                                                useValue={city}
                                                error={errorState?.city}      
                                                required={errorState?.city}
                                                onChange={unhighlight}
                                            />
                                        </Autocomplete>
                                            :
                                            "...Loading"
                                    }
                                    {/* <Dropdown 
                                        placeholder={item.placeholder} 
                                        options={Object.values(Cities)} name={item.type} 
                                        value={item.value}
                                        selectProps={{ required: item.required }} 
                                    /> */}
                                </div>
                            )
                        }

                        if (item.type === 'state') {
                            return(
                                <div className="col-md-6" key={`loc${index}`}>
                                    <Dropdown 
                                        placeholder={item.placeholder} 
                                        options={Object.values(States)} name={item.type} 
                                        value={item.value}
                                        selectProps={{ required: item.required }}  
                                    />
                                </div>
                            )
                        }

                        if (item.custom) {
                            return (
                                <div className="col-md-6" key={`loc${index}`}>
                                    <TextInput 
                                        useValue={item.value}
                                        name={item.name}
                                        disabled 
                                    />
                                </div>
                            )
                        }

                        return(
                            <div className="col-md-6">
                                <TextInput placeholder={item.placeholder} name={item.name}
                                    useValue={item.value}
                                    error={item.error}
                                    required={item.required}
                                    onChange={unhighlight}
                                 />
                            </div>
                        )
                    })
                }
                
                <div className="col-lg-12">
                    {/* <div className="property-details-google-map mb-60">
                        <iframe 
                            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d9334.271551495209!2d-73.97198251485975!3d40.668170674982946!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x89c25b0456b5a2e7%3A0x68bdf865dda0b669!2sBrooklyn%20Botanic%20Garden%20Shop!5e0!3m2!1sen!2sbd!4v1590597267201!5m2!1sen!2sbd" 
                            width="100%" 
                            height="100%"
                            frameBorder="0" allowFullScreen={true} aria-hidden="false" 
                            tabIndex={0}
                        >
                        </iframe>
                    </div> */}
                    <div className="property-details-google-map mb-60">
                        <iframe 
                            width="100%" 
                            height="100%"
                            loading="lazy" 
                            referrerPolicy="no-referrer-when-downgrade" 
                            frameBorder="0" allowFullScreen={true} aria-hidden="false" 
                            tabIndex={0}
                            src={`https://www.google.com/maps/embed/v1/place?key=AIzaSyBaTBlnl4NUU2T83KZlQwAEW-fg5R9l2Ys&q=${address}`}
                        >
                        </iframe>
                    </div>
                </div>
                {
                    mapInputs.map((item, index)=>(
                        <div className="col-md-6" key={`map${index}`}>
                            <TextInput placeholder={item.placeholder} name={item.name}
                                useValue={item.value}
                            />
                        </div>
                    ))
                }
                {/**<div className="col-md-6">
                   <Checkbox label="Enable Google Street View" />
                </div>
                <div className="col-md-6">
                    <TextInput placeholder="Google Street View - Camera Angle (value from 0 to 360)" />
            </div>*/}
            </div>
            <div className="btn-wrapper text-center--- mt-0">
                <button className="btn theme-btn-1 btn-effect-1 text-uppercase" onClick={handlePrevStep} >Prev Step</button>
                <button className="btn theme-btn-1 btn-effect-1 text-uppercase" onClick={handleNextStep} >Next Step</button>
            </div>
        </div>
    )
}

export default Location;