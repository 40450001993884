import Axios, { AxiosError, AxiosResponse } from "axios";
import useSwr, { useSWRConfig } from "swr";
import { GeneralResponse } from "../types/GeneralResponse";
import {
  CreateSavingsRequest,
  SavingsTowardsRent,
  SavingsTowardsRentRequest,
  ReleaseSavingsRequest,
  getTotalSavingsResponse,
  updateSavingsRequest,
  CreateScheduledSavingsRequest,
  TargetSaving,
} from "../types/Savings";
import api from "../axios";

const baseUrl = process.env.REACT_APP_BASE_URL || "https://api.homebuddy.ng";

interface GeneralResult {
  data?: GeneralResponse;
  error?: any;
}

const token = localStorage.getItem("token") as string;

export const saveForRent = (
  request: SavingsTowardsRentRequest,
  token?: string
) => {
  const willSaveForRent = new Promise<GeneralResult>((resolve) => {
    api.post("/api/SavingsTowardsRent/add-savingstowardsrent",
      request,
      {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
    )
      .then((response: AxiosResponse<GeneralResponse>) => {
        resolve({ data: response.data });
      })
      .catch((err: AxiosError | any) => {
        resolve({ error: err });
      });
  });

  return willSaveForRent;
};

export const updateSaveForRent = (
  request: SavingsTowardsRentRequest | updateSavingsRequest | null,
  token?: string
) => {
  const willUpdateSaveForRent = new Promise<GeneralResult>((resolve) => {
    api.post("/api/SavingsTowardsRent/update-savingstowardsrent",
      request,
      {
        headers: {
          Authorization: `Bearer ${token && token}`,
        },
      }
    )
      .then((response: AxiosResponse<GeneralResponse>) => {
        resolve({ data: response.data });
      })
      .catch((err: AxiosError | any) => {
        resolve({ error: err });
      });
  });

  return willUpdateSaveForRent;
};

export const useSavingsTowardsRent = (id: string, token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token && token}`,
    },
  };
  const fetcher = (url: string) =>
    api.get(url, config).then(
      (response: AxiosResponse<SavingsTowardsRent[]>) => response.data
    );
  const { data, error } = useSwr(
    `/api/SavingsTowardsRent/get-savingstowardsrent?userId=${id}`,
    fetcher
  ); 
  return {
    savings: data,
    isLoading: !error && !data,
    isError: error,
  };
};

export const DeleteSavings = (id: string, userId: string, token: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token && token}`,
    },
  };
  const willDeleteSavings = new Promise<GeneralResult>((resolve) => {
    api.post(
        `/api/SavingsTowardsRent/delete-savingstowardsrent?id=${id}&userId=${userId}`,
        {},
      config
    )
      .then((response: AxiosResponse<GeneralResponse>) => {
        resolve({ data: response.data });
      })
      .catch((err: AxiosError) => {
        resolve({ error: err });
      });
  });
  return willDeleteSavings;
};

interface CreateSavingsInterface {
  succeeded: boolean,
  message: string,
  errors: any,
  data: {
      releaseReference: string
  },
  meta: any,
  rentBalance: number,
  propertyPurchaseBalance: number,
  generalBalance: number,
  monthlyBillsBalance: number,
  overallBalance: number
}

interface ReleaseSavingsResponse {
  "succeeded": boolean,
  "message": string,
  "errors": any,
  "data": {
      "releaseReference": string
  },
  "meta": any,
  "rentBalance": number,
  "propertyPurchaseBalance": number,
  "generalBalance": number,
  "monthlyBillsBalance": number,
  "overallBalance": number
}

export const CreateSavings = async (
  request: CreateSavingsRequest,
  token: string
): Promise<CreateSavingsInterface> => {
  const config = {
    headers: {
      Authorization: `Bearer ${token && token}`,
    },
  };
  const { data } = await api.post(
    "/api/Payment/createSavings",
    request,
    config
  );
  return data;
};

export const ReleaseSavings = async (
  request: ReleaseSavingsRequest,
  token: string
): Promise<ReleaseSavingsResponse> => {
  const config = {
    headers: {
      Authorization: `Bearer ${token && token}`,
    },
  };
  const { data } = await api.post(
    "/api/Payment/releaseSavings",
    request,
    config
  );
  return data;
};

// export const ReleaseSavings = (request: ReleaseSavingsRequest) => {
//   const willReleaseSavings = new Promise<GeneralResult>((resolve) => {
//     Axios.post(baseUrl + '/api/Payment/releaseSavings', request, {
//       headers: {
//         Authorization: `Bearer ${token && token}`,
//       },
//     })
//       .then((response: AxiosResponse<GeneralResponse>) => {
//         resolve({ data: response.data })
//       })
//       .catch((err: AxiosError) => {
//         resolve({ error: err })
//       })
//   })
//   return willReleaseSavings
// }

// export const getTotalSavings = async (
//   token: string,
//   walletId: string,
//   email: string,
//   hold_id: string

// ): Promise<getTotalSavingsResponse> => {
//   const config = {
//     headers: {
//       Authorization: `Bearer ${token && token}`,
//     },
//   }
//   const { data } = await Axios.post(
//     baseUrl + `/api/Payment/savingsHistory`, {email, walletId, hold_id},
//     config

//   )
//   return data
// }

// https://api.homebuddy.ng/api/Payment/GetSavingsHistory?wallet_id=1509597796

// "data": {
//   "transactions": [
//       {
//           "id": 325,
//           "messageID": "651341327",
//           "accountNumber": "1718311293",
//           "destinationAccountNumber": null,
//           "amount": 400.00,
//           "transactionId": "VPHOL34d7232ee8a727",
//           "debitCreditIndicator": null,
//           "transactionDate": "2023-08-03T11:36:40.233263",
//           "responseCode": "00",
//           "transactionType": 0,
//           "transactionTypeValue": "SavingsTowardsRent",
//           "holdId": "PROPERTY_PURCHASE"
//       },

export const getTotalSavings = async (
  token: string,
  wallet_id?: string,
  PageNumber?: string,
  PageSize?: string
): Promise<getTotalSavingsResponse> => {
  const config = {
    headers: {
      Authorization: `Bearer ${token && token}`,
    },
  };

  const url = new URL(baseUrl + '/api/Payment/GetSavingsHistory')

  PageNumber && url.searchParams.set('PageNumber', PageNumber)
  PageSize && url.searchParams.set('PageSize', PageSize)

  const { data } = await api.get(
    url.toString(),
    config
  );
  return data;
};

export const callRecurrentJob = async (token: string): Promise<string> => {
  const config = {
    headers: {
      Authorization: `Bearer ${token && token}`,
    },
  };
  const { data } = await Axios.post(
    baseUrl + "/api/Payment/recurrentJob",
    {},
    config
  );
  return data;
};

export const CreateScheduledSavingsApi = async (
  request: CreateScheduledSavingsRequest,
  token: string
) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await api.post(
      `/api/SavingsTowardsRent/CreatePaymentSchedule`,
      request,
      config,
    );

    return response;
  } catch (err) {
    // @ts-ignore
    let error: AxiosError<ValidationErrors> = err; // cast the error for access
    if (!error.response) {
      throw err;
    }

    return error.response.data;
  }
};

export const getTargetSavings = async (
  token: string
) => {
  try {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    };
    const response = await api.get(
      `/api/SavingsTowardsRent/GetTargetSavings`,
      config,
    );

    return response;
  } catch (err) {
    // @ts-ignore
    let error: AxiosError<ValidationErrors> = err; // cast the error for access
    if (!error.response) {
      throw err;
    }

    return error.response.data;
  }
};


