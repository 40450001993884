import Axios, { AxiosError, AxiosResponse } from "axios";
import { SolarFinanceRequest } from "../types/Solar";


const testBaseUrl = "https://api-sandbox.homebuddy.ng";

export const applySolarFinance = (req: SolarFinanceRequest, token: string) => {
    const willPostSolar = new Promise<any>((resolve) => {
        Axios.post(testBaseUrl + "/api/Solar/Apply", req, {
            headers: {
                "Authorization": `Bearer ${token}`,
            }
        })
        .then((response: AxiosResponse<any>) => {
            resolve({data: response.data});
        })
        .catch((err: AxiosError | any) => {
            resolve({error: err});
        })
    });
    return willPostSolar;
}