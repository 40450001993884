import Landing from './pages/landing';
import Dashboard from './pages/dashboard';
import NotFound from './pages/notfound';
import SignUp from './pages/authentication/sign-up';
import HomePage from './pages/HomePage';
import AddListingPage from './pages/AddListingPage';
import PropertyGridPage from './pages/PropertyGridPage';
// import SignIn from 'pages/authentication/sign-in';
import ForgotPassword from './pages/authentication/forgotpassword';
import ConfirmEmail from './pages/authentication/confirm-email';
import SignIn from './pages/authentication/sign-in';
import ProductPage from './pages/ProductPage';
import { IRoutes } from './App';
import ProductListing from './components/Admin/pages/ProductListing';
import CheckoutPage from './pages/CheckoutPage';
import SaveForRentPage from './pages/SaveForRentPage';
import LoanPage from './pages/LoanPage';
import SignUpPage from './pages/SignUpPage';
import AccountPage from './pages/AccountPage';
import ContactUsPage from './pages/ContactUsPage';
import TransferPage from './pages/TransferPage';
import PaymentPage from './pages/PaymentPage';
import FAQPage from './pages/FAQPage';
import UtilityBillPage from './pages/UtilityBillPage';
import ResetPassword from './pages/authentication/reset_password';
import SignUpSuccess from './pages/authentication/sign-up-success';
import UpdateSavings from './components/manageSavings/UpdateSaving';
import ResetPin from './components/Dialogs/ResetPin/ResetPin';
import walletTransfer from './components/WalletTransfer/walletTransfer';
import OurServicesPage from './pages/OurServicesPage';
import MyListings from './pages/MyListings';
import MortgagePage from './pages/MortagePage'
import MarketPlace from './pages/MarketPlace';
import MarketProductPage from './pages/MarketProductPage';
import Logout from './pages/authentication/logout';
import SavedProperties from './pages/SavedProperties'
import AboutUsPage from './pages/AboutUsPage/AboutUsPage';
import OurAboutPage from './pages/AboutUsPage/OurAboutPage';
import OpenAccessPensions from './pages/OpenAccessPensions';
import ViewPensionsPage from './pages/ViewPensionApplications';
import ViewPensionApplicant from './pages/ViewPensionApplications/ViewApplicant';
import AdminDashboard from './pages/AdminDashboard';
import ViewMortgagePage from './pages/ViewMortgageApplicants';
import ViewMortgageApplicant from './pages/ViewMortgageApplicants/ViewMortgageApplicant';
import DeleteAccountPage from './pages/DeleteAccount';
import InsurancePage from './pages/Insurance';
import RentInsurancePage from './pages/Insurance/Rent';
import InsuranceApplicationPage from './pages/Insurance/InsuranceApplication';
import HouseHoldPage from './pages/Insurance/HouseHold';
import HomeOwnersInsurancePage from './pages/Insurance/HomeOwners';
import AllInsurancesPage from './pages/RentInsuranceAdminDashboard/AllInsurances';
import SingleInsurance from './pages/RentInsuranceAdminDashboard/SingleInsurance';
import ListerProperties from './pages/ListerProperties/index'
import SolarFinancing from "./pages/SolarFinancing/index"
import SmartHomes from './pages/SmartHomes';
import AgentProperties from './sections/AgentProperties';
import AgentListings from './pages/AgentListings';

const routes: IRoutes[] = [

    // Commmented out by Ikem
   /* {
        type: 'collapse',
        name: 'Landing',
        key: 'landing',
        route: '/',
        component: Landing,
        noCollapse: true,
    },*/
    {
        type: 'collapse',
        name: 'Landing',
        key: 'landing',
        route: '/',
        component: HomePage,
        noCollapse: true,
    },
    {
        type: 'collapse',
        name: 'SignUp',
        key: 'signup',
        route: '/signup',
        component: SignUpPage,
        noCollapse: true,
        protectedAuth: true,
    },
    {
        type: 'collapse',
        name: 'ConfirmEmail',
        key: 'confirmEmail',
        route: '/signup/verify',
        component: ConfirmEmail,
        noCollapse: true,
        // protected: true,
        // redirectPath: '/signup'
    },
    {
        type: 'collapse',
        name: 'SignUpSuccess',
        key: 'signUpSuccess',
        route: '/signup/success',
        component: SignUpSuccess,
        noCollapse: true,
        // protected: true,
        // redirectPath: '/signup'
    },
    {
        type: 'collapse',
        name: 'Logout',
        key: 'logout',
        route: '/logout',
        component: Logout,
        noCollapse: true,
        // protected: true,
        // redirectPath: '/signup'
    },
    {
        type: 'collapse',
        name: 'SignIn',
        key: 'signin',
        route: '/signin',
        component: SignIn,
        protectedAuth: true,
        noCollapse: true,
    },
    {
        type: 'collapse',
        name: 'ResetPassword',
        key: 'resetPaaword',
        route: '/reset-password',
        component: ResetPassword,
        protectedAuth: true,
        noCollapse: true,
    },
    {
        type: 'collapse',
        name: 'Dashboard',
        key: 'dashboard',
        route: '/dashboard',
        component: Dashboard,
        protected: true,
        noCollapse: true,
    },
    {
        type: 'collapse',
        name: 'My Listings',
        key: 'personalized-listings',
        route: '/mylistings',
        component: MyListings,
        protected: true,
        noCollapse: true,
    },
    {
        type: 'collapse',
        name: 'ForgotPassword',
        key: 'forgotpassword',
        route: '/forgotPassword',
        component: ForgotPassword,
        protectedAuth: true,
        noCollapse: true,

    },
    {
        type: 'collapse',
        name: 'NotFound',
        key: 'notfound',
        route: '/notfound',
        component: NotFound,
        noCollapse: true,
    },
    // {
    //     type: 'collapse',
    //     name: 'UpdateSavings',
    //     key: 'updatesavings',
    //     route: '/savings/:id',
    //     component: UpdateSavings,
    //     noCollapse: true,
    // },

    /** Added other pages here */
    {
        type: 'collapse',
        name: 'AddListings',
        key: 'addListings',
        route: '/add-listing',
        component: AddListingPage,
        noCollapse: true,
        protected: true,
        privileges: ['Admin', 'Agent', 'Landlord']
    },

    {
        type: 'collapse',
        name: 'listings',
        key: 'properties',
        route: '/properties',
        component: PropertyGridPage,
        noCollapse: true,
    },

    {
        type: 'collapse',
        name: 'Product',
        key: 'product',
        route: '/properties/:id',
        component: ProductPage,
        noCollapse: true,
    },
    
    {
        type: 'collapse',
        name: 'Market Product',
        key: 'market-product',
        route: '/product-details/:id',
        component: MarketProductPage,
        noCollapse: true,
    },

    {
        type: 'collapse',
        name: 'Admin',
        key: 'admin',
        route: '/propadmin',
        component: ProductListing,
        noCollapse: true,
        protected: true,
    },
    {
        type: 'collapse',
        name: 'Reset-Pin',
        key: 'reset-pin',
        route: '/resetpin/:propertyId?',
        component: ResetPin,
        noCollapse: true,
        protected: true,
    },

    // {
    //     type: 'collapse',
    //     name: 'Checkout',
    //     key: 'checkout',
    //     route: '/checkout',
    //     component: CheckoutPage,
    //     noCollapse: true,
    // },

    {
        type: 'collapse',
        name: 'Save For Rent',
        key: 'saveForRent',
        route: '/save-for-rent',
        protected: true,
        component: SaveForRentPage,
        noCollapse: true,
    },

    {
        type: 'collapse',
        name: 'Transfer',
        key: 'transfer',
        route: '/transfer',
        protected: true,
        component: TransferPage,
        noCollapse: true,
    },

    {
        type: 'collapse',
        name: 'Loan',
        key: 'loan',
        route: '/loan',
        protected: true,
        component: LoanPage,
        noCollapse: true,
    },

    {
        type: 'collapse',
        name: 'Mortgage',
        key: 'mortgage',
        route: '/mortgage',
        protected: false,
        component: MortgagePage,
        noCollapse: true,
    },
    {
        type: 'collapse',
        name: 'OpenAccessPension',
        key: 'openaccesspension',
        route: '/openaccesspension/:id',
        protected: true,
        component: OpenAccessPensions,
        noCollapse: true,
    },

    {
        type: 'collapse',
        name: 'ViewPensions',
        key: 'viewpensions',
        route: '/viewpensions',
        protected: true,
        component: ViewPensionsPage,
        noCollapse: true,
    },
    {
        type: 'collapse',
        name: 'ViewPensions',
        key: 'viewpensions',
        route: '/viewmortgage',
        protected: true,
        component: ViewMortgagePage,
        noCollapse: true,
    },
    {
        type: 'collapse',
        name: 'ViewInsurance',
        key: 'viewinsurance',
        route: '/viewrentinsurance',
        protected: true,
        component: AllInsurancesPage,
        noCollapse: true,
    },
    {
        type: 'collapse',
        name: 'View Pension Applicant',
        key: 'viewpensionsapplicant',
        route: '/viewpensions/:id',
        protected: true,
        component: ViewPensionApplicant,
        noCollapse: true,
    },
    {
        type: 'collapse',
        name: 'View Mortgage Applicant',
        key: 'viewmortgageapplicant',
        route: '/viewmortgage/:id',
        protected: true,
        component: ViewMortgageApplicant,
        noCollapse: true,
    },
    {
        type: 'collapse',
        name: 'View Insurance Applicant',
        key: 'viewinsuranceapplicant',
        route: '/viewrentinsurance/:id',
        protected: true,
        component: SingleInsurance,
        noCollapse: true,
    },
    {
        type: 'collapse',
        name: 'Saved Listings',
        key: 'savedlistings',
        route: '/savedProperties',
        protected: true,
        component: SavedProperties,
        noCollapse: true,
    },
    {
        type: 'collapse',
        name: 'Similar Properties',
        key: 'similarproperties',
        route: '/similarproperties',
        protected: true,
        component: ListerProperties,
        noCollapse: true,
    },
    {
        type: 'collapse',
        name: 'Dashboard Options',
        key: 'dashboardOptions',
        route: '/dashboardad',
        protected: true,
        component: AdminDashboard,
        noCollapse: true,
    },
    {
        type: 'collapse',
        name: 'Delete Account',
        key: 'deleteAccount',
        route: '/deletehomebuddyaccount',
        protected: true,
        component: DeleteAccountPage,
        noCollapse: true,
    },
    {
        type: 'collapse',
        name: 'Insurance',
        key: 'insurance',
        route: '/insurance',
        protected: true,
        component: InsurancePage,
        noCollapse: true,
    },
    {
        type: 'collapse',
        name: 'Insurance',
        key: 'insurance',
        route: '/insurance/rent',
        protected: true,
        component: RentInsurancePage,
        noCollapse: true,
    },
    {
        type: 'collapse',
        name: 'Insurance',
        key: 'insurance',
        route: '/insurance/household',
        protected: true,
        component: HouseHoldPage,
        noCollapse: true,
    },
    {
        type: 'collapse',
        name: 'Insurance',
        key: 'insurance',
        route: '/insurance/homeOwners',
        protected: true,
        component: HomeOwnersInsurancePage,
        noCollapse: true,
    },
    {
        type: 'collapse',
        name: 'Insurance',
        key: 'insurance',
        route: '/insurance/requestandbuy',
        protected: true,
        component: InsuranceApplicationPage,
        noCollapse: true,
    },
    {
        type: 'collapse',
        name: 'Agent Properties',
        key: 'agent-properties',
        route: '/agent-properties',
        component: AgentListings,
        noCollapse: true,
    },
    {
        type: 'collapse',
        name: 'Solar Financing',
        key: 'solar-financing',
        route: '/solar-financing',
        component: SolarFinancing,
        noCollapse: true
    },
    {
        type: 'collapse',
        name: 'Smart Home Services',
        key: 'smart-homes',
        route: '/smart-homes',
        component: SmartHomes,
        noCollapse: true
    },

    {
        type: 'collapse',
        name: 'Account',
        key: 'account',
        route: '/account',
        protected: true,
        component: AccountPage,
        noCollapse: true,
    },

    {
        type: 'collapse',
        name: 'Contact',
        key: 'contact',
        route: '/contact',
        protected: false,
        component: ContactUsPage,
        noCollapse: true,
    },

    // {
    //     type: 'collapse',
    //     name: 'Our Services',
    //     key: 'our-services',
    //     route: '/our-services',
    //     protected: false,
    //     component: OurServicesPage,
    //     noCollapse: true,
    // },
    {
        type: 'collapse',
        name: 'About Us',
        key: 'about-us',
        route: '/about-us',
        protected: false,
        component: OurAboutPage,
        noCollapse: true,
    },

    {
        type: 'collapse',
        name: 'Payment',
        key: 'payment',
        route: '/payment',
        protected: true,
        component: PaymentPage,
        noCollapse: true,
    },

    {
        type: 'collapse',
        name: 'FAQ',
        key: 'faq',
        route: '/faq',
        component: FAQPage,
        noCollapse: true,
    },

    {
        type: 'collapse',
        name: 'UtilityBills',
        key: 'utility-bill',
        route: '/utility-bills',
        component: UtilityBillPage,
        noCollapse: true,
        protected: true
    },

    {
        type: 'collapse',
        name: 'Wallet Transfer',
        key: 'payment',
        route: '/wallet-transfer/:id?',
        protected: true,
        component: walletTransfer,
        noCollapse: true,
    },
    {
        type: 'collapse',
        name: 'Update Savings',
        key: 'update-savings',
        route: '/savings/:id',
        protected: true,
        component: UpdateSavings,
        noCollapse: true,
    },

];

export default routes;
