import React, { useEffect, useState } from "react";
import avatar from "../../assets/avatar.jpeg";
import "./userAccountDash.scss";
import { getUser, useGetUser } from "../../swr/user";
import { useStateContext } from "../../contexts/StateContext";
import { Link } from "react-router-dom";
import transactIcon from "../../assets/transactIcon.svg";
import rentPropIcon from "../../assets/rentedPropIcon.svg";
import availableIcon from "../../assets/availableIcon.svg";
import mortgageIcon from "../../assets/mortgageIcon.svg";
import soldIcon from "../../assets/soldIcon.svg";
import savedIcon from "../../assets/savedIcon.svg";
import inspectIcon from "../../assets/inspectIcon.svg";
import dropdown from "../../assets/dropdown.svg";
import calendarIcon from "../../assets/calendarIcon.svg";
import ModalLayout from "../../components/ModalLayout";
import PopUp from "../../components/Insurance/PopUp";

export interface UserAccountDashboardProps {
  email: string | undefined;
  firstName: string;
  lastName: string;
  phoneNumber: string;
  userName: string;
}

const transactions = [
  {
    name: "Ashley Whites Terraces",
    address: "1213, Hertfordshire Lake, Victoria Island, Lagos",
    time: "06:30PM",
    date: "Tue, March 26, 2024",
    amount: "₦ 20,000,000.00",
    status: "Completed",
  },
  {
    name: "First Mortgage Duplex",
    address: "1213, Hertfordshire Lake, Victoria Island, Lagos",
    time: "06:30PM",
    date: "Mon, March 25, 2024",
    amount: "₦ 20,000,000.00",
    status: "Pending",
  },
  {
    name: "Dukia Homes",
    address: "1213, Hertfordshire Lake, Victoria Island, Lagos",
    time: "06:30PM",
    date: "Tue, March 26, 2024",
    amount: "₦ 20,000,000.00",
    status: "Part Payment",
  },
  {
    name: "Ashley Whites Terraces",
    address: "1213, Hertfordshire Lake, Victoria Island, Lagos",
    time: "06:30PM",
    date: "Wed, Feb 14, 2024",
    amount: "₦ 20,000,000.00",
    status: "Completed",
  },
  {
    name: "First Mortgage Duplex",
    address: "1213, Hertfordshire Lake, Victoria Island, Lagos",
    time: "06:30PM",
    date: "Mon, March 25, 2024",
    amount: "₦ 20,000,000.00",
    status: "Pending",
  },
];

const categories = ["Rentals", "Purchases", "Mortgages"];

const UserAccountDashboard: React.FC<UserAccountDashboardProps> = () => {
  const token = localStorage.getItem("token");
  const userEmail = localStorage.getItem("email");

  const { email } = useStateContext();
  const { user, isLoading, isError } = useGetUser(userEmail as string, token!);
  const [showTransactionDetails, setShowTransactionDetails] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [text, setText] = useState("Category");
  // console.log(user)

  // const img = reader.readAsDataURL(user!.image as Blob);
  // const [image, setImage] = useState<string | null>('');
  // console.log(user);
  // if(user && user.imageURL){
  //   // const binaryString = window.atob(user.image);
  //   // const blob = new Blob([binaryString], { type: 'image/jpeg' });
  //   // const imageData = URL.createObjectURL(blob);
  //   setImage(user.imageURL)
  // }

  // useEffect(() => {
  //   const storedImage = localStorage.getItem("photo");

  //   if (storedImage && !user?.image) {

  //     setImage(storedImage);
  //   }
  // }, []);

  const logout = () => {
    localStorage.removeItem("paymentState");
    localStorage.removeItem("token");
    localStorage.removeItem("id");
    localStorage.removeItem("email");
    localStorage.removeItem("homezone_wallet");
    localStorage.removeItem("paymentState");
  };

  return (
    <div className="tab-pane fade active show" id="liton_tab_1_1">
      <div
        className={`homebuddy_info_layout ${
          showTransactionDetails === true ? "disappear" : ""
        }`}
      >
        <div className="first_section">
          <div className="transact_card">
            <img src={transactIcon} alt="" />
            <span className="boldText">₦50,500,000.00</span>
            <span className="caption">
              Total amount of completed transactions
            </span>
            <span className="view_link">View</span>
          </div>

          <div className="rented_card">
            <img src={rentPropIcon} alt="" />
            <span className="boldText">2,000</span>
            <span className="caption">Rented Properties</span>
          </div>
        </div>

        <div className="second_section">
          <div className="viewPropCard">
            <img src={availableIcon} alt="" />
            <span className="boldText">1,400</span>
            <span className="caption">Viewed Properties</span>
          </div>

          <div className="gridCard">
            <div className="first_grid">
              <div className="mortgage_card">
                <img src={mortgageIcon} alt="" />
                <span className="boldText">400</span>
                <span className="caption">Mortgage Offer Submissions</span>
              </div>

              <div className="purchase_card">
                <img src={soldIcon} alt="" />
                <span className="boldText">200</span>
                <span className="caption">Purchased Properties</span>
              </div>
            </div>

            <div className="last_grid">
              <div className="saved_prop_card">
                <img src={savedIcon} alt="" />
                <span className="boldText">1,800</span>
                <span className="caption">Saved Properties</span>
                <span className="view_link">View</span>
              </div>

              <div className="inspect_card">
                <img src={inspectIcon} alt="" />
                <span className="boldText">100</span>
                <span className="caption">Inspection Request</span>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className={`${showTransactionDetails === false ? "disappear" : ""}`}>
        <div className="transac_header">
          <span className="boldText">₦50,500,000.00</span>
          <span className="caption">
            Total amount of completed transactions
          </span>
        </div>

        <div className="transac_drop_down">
          <span>Transaction History</span>
          <div className="category_view">
            <span>{text}</span>
            <img
              src={dropdown}
              alt=""
              onClick={() => setShowModal(!showModal)}
            />
          </div>
        </div>

        <div className="transac_table">
          {transactions.map((item, index) => (
            <div
              key={index}
              className={`transac_item ${
                index + 1 < transactions.length ? "bottom_style" : null
              }`}
            >
              <img src={calendarIcon} alt="" className="itemImage" />
              <div className="name_address_time">
                <span className="name">{item.name}</span>
                <span className="address">{item.address}</span>
                <span className="time">{item.time}</span>
              </div>
              <span className="dateText">{item.date}</span>
              <span className="amountText">{item.amount}</span>

              <span
                className={`status ${
                  item.status === "Completed"
                    ? "completed"
                    : item.status === "Pending"
                    ? "pending"
                    : item.status === "Part Payment"
                    ? "part-payment"
                    : "default"
                }`}
              >
                {item.status}
              </span>
            </div>
          ))}
        </div>

        <div className="view_nav">
          <span className="view_link">View More</span>
        </div>

        <div className="mobile_transac_view">
          <span>Click on the button below to view transactions</span>
          <button className="view_transac_btn">View Transactions</button>
        </div>

        <ModalLayout modalOpen={showModal === true}>
          <PopUp
            options={categories}
            click={(selectedOption: string) => {
              setText(selectedOption);
              setShowModal(false);
            }}
          />
        </ModalLayout>
      </div>

      <div className="ltn__myaccount-tab-content-inner">
        <div className="profile">
          {user && user.imageURL ? (
            <img className="demo_logo_last" src={user.imageURL} alt="Avatar" />
          ) : (
            <img className="demo_logo_last" src={avatar} alt="Avatar" />
          )}
          <h3>{user?.userName}</h3>
        </div>
        <p>
          Hello <strong>{user?.userName}</strong> (not{" "}
          <strong>{user?.userName}</strong>?{" "}
          <small>
            <a href="/logout">Log out</a>
          </small>{" "}
          )
        </p>
        <p>
          From your account dashboard you can view your <span>information</span>
        </p>
        <p>Username: {user?.userName}</p>
        <p>First Name: {user?.firstName}</p>
        <p>Last Name: {user?.lastName}</p>
        <p>Phone Number: {user?.phoneNumber}</p>
        <p>Email: {user?.email}</p>
        <p>Referral Code: {user?.referralCode}</p>
        <p>
          <Link to="/deletehomebuddyaccount">Delete Account</Link>
        </p>
      </div>
      <div className="">how far bosssss</div>
    </div>
  );
};

export default UserAccountDashboard;
