import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../state/store";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import {
  ApplyInsuranceII,
  CreateQuoteResponseData,
  GetQuoteCategoryPair,
  HomeOwnersGetQuoteReq,
  QuoteInfoDetails,
  RentDropDown,
  assetTypeList,
  categoryList,
  contentAssetTypeList,
  contentCategoryList,
} from "../../../../types/Insurance";
import styles from "./home-owners.module.scss";
import PopUp from "../../../../components/Insurance/PopUp";
import ModalLayout from "../../../../components/ModalLayout";
import { useDispatch } from "react-redux";
import {
  setDisplayExcelData,
  setExcelFile,
  setGetAQuoteReq,
  setHomeOwnersGetQuoteRequest,
  setHomeOwnerGetQuoteRes,
} from "../../../../state/reducers/insuranceSlice";
import ToastContext from "../../../../contexts/ToastContext";
import {
  capitalizeFirstLetter,
  convertKeysToLowerCase,
  formatAmount,
  returnMsgFromAxiosError,
} from "../../../../utils/generalUtils";
import UploadSingleButton from "../../../../components/Insurance/UploadDocs/UploadSingleButton.tsx";
import { utils, read } from "xlsx";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { homeOwnersCreateQuoteApi } from "../../../../swr/insurance";
import LoadingContext from "../../../../contexts/LoadingContext";

const propertyTypeArr = [
  "Bungalow",
  "Storey Building",
  "Duplex",
  "Semi-detached",
];

const coverageArr = ["3 months", "6 months"];

export type ExpectedJSONFromExcel = {
  items: string;
  units: string;
  value: string;
};

const acceptedKeys = ["items", "units", "value"];

const itemsToBeDisplayed = [
  "building",
  "laptop",
  "phone",
  "jewelries",
  "wristwatch",
  "camera",
];

const contentItemsToBeDisplayed = [
  "airconditioner",
  "television",
  "refrigerator",
  "freezer",
  "hometheatre",
  "table",
  "gascooker",
  "inverter",
  "bed",
  "waterdispenser",
  "washingmachine",
  "kitchencabinets",
];

const acceptedObjKeys = [
  "Building",
  "LapTop",
  "Phone",
  "Jewelries",
  "WristWatch",
  "Camera",
  "AirConditioner",
  "Television",
  "Refrigerator",
  "Freezer",
  "HomeTheatre",
  "Table",
  "GasCooker",
  "Inverter",
  "Bed",
  "WaterDispenser",
  "WashingMachine",
  "KitchenCabinets",
];

export const transformExpectedJSONToHomeOwnersReq = (
  policyDuration: string,
  contact: string | undefined,
  data: ExpectedJSONFromExcel[]
): HomeOwnersGetQuoteReq => {
  const homeOwnersReq: HomeOwnersGetQuoteReq = {
    PolicyDuration: policyDuration,
    ContactAddress: contact,
  };

  for (const item of data) {
    const key = acceptedObjKeys.find(each => each.toLowerCase() === item.items.toLowerCase()) as string;
    const value = parseInt(item.value);
    const units = parseInt(item.units);

    if (
      // key === "Building" ||
      // key === "LapTop" ||
      // key === "Phone" ||
      // key === "Jewelries" ||
      // key === "WristWatch" ||
      // key === "Camera"
      acceptedObjKeys.includes(key)
    ) {
      //@ts-ignore
      homeOwnersReq[key] = {
        NumberOfUnits: units.toString(),
        Value: value.toString(),
        selected: true,
      };
    } else if (key !== "Others") {
      // Unexpected key found, initialize Others category
      homeOwnersReq.Others = homeOwnersReq.Others || {
        NumberOfUnits: "0",
        Value: "0",
        selected: false,
      };
      homeOwnersReq.Others.NumberOfUnits = (
        parseInt(homeOwnersReq.Others.NumberOfUnits) + units
      ).toString();
      homeOwnersReq.Others.Value = (
        parseInt(homeOwnersReq.Others.Value) + value
      ).toString();
    }
  }

  // Remove Others category if it's empty
  if (Object.keys(homeOwnersReq.Others || {}).length === 0) {
    delete homeOwnersReq.Others;
  }

  return homeOwnersReq;
};

const isPolicyDurationEmptyOrAllSelectedFalse = (
  data: HomeOwnersGetQuoteReq
) => {
  // Check if PolicyDuration is ""
  if (data.PolicyDuration === "") {
    return { bol: true, field: "pd" };
  }

  if (data.ContactAddress === "") {
    return { bol: true, field: "ca" };
  }

  // Check if all selected fields are false
  for (const key in data) {
    // Exclude PolicyDuration from the check
    if (
      key !== "PolicyDuration" &&
      (data[key as keyof HomeOwnersGetQuoteReq] as GetQuoteCategoryPair)
        .selected
    ) {
      return { bol: false, field: "" };
    }
  }

  // If PolicyDuration is not "" and all selected fields are false
  return { bol: true, field: "items" };
};

const filterSelectedTrue = (
  data: HomeOwnersGetQuoteReq
): HomeOwnersGetQuoteReq => {
  const filteredData: HomeOwnersGetQuoteReq = { ...data };

  // Remove keys with selected: false
  for (const key in filteredData) {
    const value = filteredData[key as keyof HomeOwnersGetQuoteReq];
    if (value && typeof value !== "string" && value.selected === false) {
      delete filteredData[key as keyof HomeOwnersGetQuoteReq];
    }
  }

  return filteredData;
};

const isAnySelectedTrueWithNotEmptyValues = (data: HomeOwnersGetQuoteReq) => {
  for (const key in data) {
    const innerKey = data[
      key as keyof HomeOwnersGetQuoteReq
    ] as GetQuoteCategoryPair;
    if (key !== "PolicyDuration" && innerKey.selected) {
      if (innerKey.NumberOfUnits === "" || innerKey.Value === "") {
        return { bool: false, key }; // If any selected field has empty NumberOfUnits or Value, return false
      }
    }
  }

  return { bool: true, key: "" }; // If all selected fields have non-empty NumberOfUnits and Value, or no selected fields, return true
};

const GetAQuote = ({
  setRentStep,
  closeAllDropDowns,
  rentDropDownValue,
}: {
  setRentStep: ActionCreatorWithPayload<number, "insurance/setRentStep">;
  closeAllDropDowns: () => void;
  rentDropDownValue: RentDropDown;
}) => {
  const dispatch = useDispatch();
  const { openError, openSuccess } = useContext(ToastContext);
  const {
    excelFile,
    displayExcelData,
    HomeOwnersGetQuoteRequest,
    personalDetails,
    HomeOwnerGetQuoteRes,
  } = useSelector((state: RootState) => state.insurance);
  const { setLoading } = useContext(LoadingContext);
  const token = localStorage.getItem("token") as string;

  const [buildingData, setBuildingData] = useState<
    ExpectedJSONFromExcel | undefined
  >();
  const [notBuildingData, setNotBuildingData] = useState<
    ExpectedJSONFromExcel[] | []
  >([]);
  const [contentData, setContentData] = useState<ExpectedJSONFromExcel[] | []>(
    []
  );

  const [getAQuoteHm, setgetAQuoteHm] = useState<HomeOwnersGetQuoteReq>({
    PolicyDuration: "",
    ContactAddress: "",
    Building: {
      NumberOfUnits: "",
      Value: "",
      selected: false,
    },
    LapTop: {
      NumberOfUnits: "",
      Value: "",
      selected: false,
    },
    Phone: {
      NumberOfUnits: "",
      Value: "",
      selected: false,
    },
    Jewelries: {
      NumberOfUnits: "",
      Value: "",
      selected: false,
    },
    WristWatch: {
      NumberOfUnits: "",
      Value: "",
      selected: false,
    },
    Camera: {
      NumberOfUnits: "",
      Value: "",
      selected: false,
    },
    Others: {
      NumberOfUnits: "",
      Value: "",
      selected: false,
    },
    AirConditioner: {
      NumberOfUnits: "",
      Value: "",
      selected: false,
    },
    Television: {
      NumberOfUnits: "",
      Value: "",
      selected: false,
    },
    Refrigerator: {
      NumberOfUnits: "",
      Value: "",
      selected: false,
    },
    Freezer: {
      NumberOfUnits: "",
      Value: "",
      selected: false,
    },
    HomeTheatre: {
      NumberOfUnits: "",
      Value: "",
      selected: false,
    },
    Table: {
      NumberOfUnits: "",
      Value: "",
      selected: false,
    },
    GasCooker: {
      NumberOfUnits: "",
      Value: "",
      selected: false,
    },
    Inverter: {
      NumberOfUnits: "",
      Value: "",
      selected: false,
    },
    Bed: {
      NumberOfUnits: "",
      Value: "",
      selected: false,
    },
    WaterDispenser: {
      NumberOfUnits: "",
      Value: "",
      selected: false,
    },
    WashingMachine: {
      NumberOfUnits: "",
      Value: "",
      selected: false,
    },
    KitchenCabinets: {
      NumberOfUnits: "",
      Value: "",
      selected: false,
    },
  });

  const sectionBArr = [
    {
      name: "AirConditioner",
      unitsVal: getAQuoteHm.AirConditioner?.NumberOfUnits,
      unitsDef: getAQuoteHm.AirConditioner?.NumberOfUnits,
      unitsName: "AirConditioner.NumberOfUnits",
      valueName: "AirConditioner.Value",
      selectedName: "AirConditioner.selected",
      selectedVal: getAQuoteHm.AirConditioner?.selected,
      valueVal: getAQuoteHm.AirConditioner?.Value,
      valueDef: getAQuoteHm.AirConditioner?.Value,
    },
    {
      name: "Television",
      unitsVal: getAQuoteHm.Television?.NumberOfUnits,
      unitsDef: getAQuoteHm.Television?.NumberOfUnits,
      unitsName: "Television.NumberOfUnits",
      valueName: "Television.Value",
      selectedName: "Television.selected",
      selectedVal: getAQuoteHm.Television?.selected,
      valueVal: getAQuoteHm.Television?.Value,
      valueDef: getAQuoteHm.Television?.Value,
    },
    {
      name: "Refrigerator",
      unitsVal: getAQuoteHm.Refrigerator?.NumberOfUnits,
      unitsDef: getAQuoteHm.Refrigerator?.NumberOfUnits,
      unitsName: "Refrigerator.NumberOfUnits",
      valueName: "Refrigerator.Value",
      selectedName: "Refrigerator.selected",
      selectedVal: getAQuoteHm.Refrigerator?.selected,
      valueVal: getAQuoteHm.Refrigerator?.Value,
      valueDef: getAQuoteHm.Refrigerator?.Value,
    },
    {
      name: "Freezer",
      unitsVal: getAQuoteHm.Freezer?.NumberOfUnits,
      unitsDef: getAQuoteHm.Freezer?.NumberOfUnits,
      unitsName: "Freezer.NumberOfUnits",
      valueName: "Freezer.Value",
      selectedName: "Freezer.selected",
      selectedVal: getAQuoteHm.Freezer?.selected,
      valueVal: getAQuoteHm.Freezer?.Value,
      valueDef: getAQuoteHm.Freezer?.Value,
    },
    {
      name: "HomeTheatre",
      unitsVal: getAQuoteHm.HomeTheatre?.NumberOfUnits,
      unitsDef: getAQuoteHm.HomeTheatre?.NumberOfUnits,
      unitsName: "HomeTheatre.NumberOfUnits",
      valueName: "HomeTheatre.Value",
      selectedName: "HomeTheatre.selected",
      selectedVal: getAQuoteHm.HomeTheatre?.selected,
      valueVal: getAQuoteHm.HomeTheatre?.Value,
      valueDef: getAQuoteHm.HomeTheatre?.Value,
    },
    {
      name: "Table",
      unitsVal: getAQuoteHm.Table?.NumberOfUnits,
      unitsDef: getAQuoteHm.Table?.NumberOfUnits,
      unitsName: "Table.NumberOfUnits",
      valueName: "Table.Value",
      selectedName: "Table.selected",
      selectedVal: getAQuoteHm.Table?.selected,
      valueVal: getAQuoteHm.Table?.Value,
      valueDef: getAQuoteHm.Table?.Value,
    },
    {
      name: "GasCooker",
      unitsVal: getAQuoteHm.GasCooker?.NumberOfUnits,
      unitsDef: getAQuoteHm.GasCooker?.NumberOfUnits,
      unitsName: "GasCooker.NumberOfUnits",
      valueName: "GasCooker.Value",
      selectedName: "GasCooker.selected",
      selectedVal: getAQuoteHm.GasCooker?.selected,
      valueVal: getAQuoteHm.GasCooker?.Value,
      valueDef: getAQuoteHm.GasCooker?.Value,
    },
    {
      name: "Inverter",
      unitsVal: getAQuoteHm.Inverter?.NumberOfUnits,
      unitsDef: getAQuoteHm.Inverter?.NumberOfUnits,
      unitsName: "Inverter.NumberOfUnits",
      valueName: "Inverter.Value",
      selectedName: "Inverter.selected",
      selectedVal: getAQuoteHm.Inverter?.selected,
      valueVal: getAQuoteHm.Inverter?.Value,
      valueDef: getAQuoteHm.Inverter?.Value,
    },
    {
      name: "Bed",
      unitsVal: getAQuoteHm.Bed?.NumberOfUnits,
      unitsDef: getAQuoteHm.Bed?.NumberOfUnits,
      unitsName: "Bed.NumberOfUnits",
      valueName: "Bed.Value",
      selectedName: "Bed.selected",
      selectedVal: getAQuoteHm.Bed?.selected,
      valueVal: getAQuoteHm.Bed?.Value,
      valueDef: getAQuoteHm.Bed?.Value,
    },
    {
      name: "WaterDispenser",
      unitsVal: getAQuoteHm.WaterDispenser?.NumberOfUnits,
      unitsDef: getAQuoteHm.WaterDispenser?.NumberOfUnits,
      unitsName: "WaterDispenser.NumberOfUnits",
      valueName: "WaterDispenser.Value",
      selectedName: "WaterDispenser.selected",
      selectedVal: getAQuoteHm.WaterDispenser?.selected,
      valueVal: getAQuoteHm.WaterDispenser?.Value,
      valueDef: getAQuoteHm.WaterDispenser?.Value,
    },
    {
      name: "WashingMachine",
      unitsVal: getAQuoteHm.WashingMachine?.NumberOfUnits,
      unitsDef: getAQuoteHm.WashingMachine?.NumberOfUnits,
      unitsName: "WashingMachine.NumberOfUnits",
      valueName: "WashingMachine.Value",
      selectedName: "WashingMachine.selected",
      selectedVal: getAQuoteHm.WashingMachine?.selected,
      valueVal: getAQuoteHm.WashingMachine?.Value,
      valueDef: getAQuoteHm.WashingMachine?.Value,
    },
    {
      name: "KitchenCabinets",
      unitsVal: getAQuoteHm.KitchenCabinets?.NumberOfUnits,
      unitsDef: getAQuoteHm.KitchenCabinets?.NumberOfUnits,
      unitsName: "KitchenCabinets.NumberOfUnits",
      valueName: "KitchenCabinets.Value",
      selectedName: "KitchenCabinets.selected",
      selectedVal: getAQuoteHm.KitchenCabinets?.selected,
      valueVal: getAQuoteHm.KitchenCabinets?.Value,
      valueDef: getAQuoteHm.KitchenCabinets?.Value,
    },
  ];

  const sectionCArr = [
    {
      name: "LapTop",
      unitsVal: getAQuoteHm.LapTop?.NumberOfUnits,
      unitsDef: getAQuoteHm.LapTop?.NumberOfUnits,
      unitsName: "LapTop.NumberOfUnits",
      valueName: "LapTop.Value",
      selectedName: "LapTop.selected",
      selectedVal: getAQuoteHm.LapTop?.selected,
      valueVal: getAQuoteHm.LapTop?.Value,
      valueDef: getAQuoteHm.LapTop?.Value,
    },
    {
      name: "Phone",
      unitsVal: getAQuoteHm.Phone?.NumberOfUnits,
      unitsDef: getAQuoteHm.Phone?.NumberOfUnits,
      unitsName: "Phone.NumberOfUnits",
      valueName: "Phone.Value",
      selectedName: "Phone.selected",
      selectedVal: getAQuoteHm.Phone?.selected,
      valueVal: getAQuoteHm.Phone?.Value,
      valueDef: getAQuoteHm.Phone?.Value,
    },
    {
      name: "Jewelries",
      unitsVal: getAQuoteHm.Jewelries?.NumberOfUnits,
      unitsDef: getAQuoteHm.Jewelries?.NumberOfUnits,
      unitsName: "Jewelries.NumberOfUnits",
      valueName: "Jewelries.Value",
      selectedName: "Jewelries.selected",
      selectedVal: getAQuoteHm.Jewelries?.selected,
      valueVal: getAQuoteHm.Jewelries?.Value,
      valueDef: getAQuoteHm.Jewelries?.Value,
    },
    {
      name: "WristWatch",
      unitsVal: getAQuoteHm.WristWatch?.NumberOfUnits,
      unitsDef: getAQuoteHm.WristWatch?.NumberOfUnits,
      unitsName: "WristWatch.NumberOfUnits",
      valueName: "WristWatch.Value",
      selectedName: "WristWatch.selected",
      selectedVal: getAQuoteHm.WristWatch?.selected,
      valueVal: getAQuoteHm.WristWatch?.Value,
      valueDef: getAQuoteHm.WristWatch?.Value,
    },
    {
      name: "Camera",
      unitsVal: getAQuoteHm.Camera?.NumberOfUnits,
      unitsDef: getAQuoteHm.Camera?.NumberOfUnits,
      unitsName: "Camera.NumberOfUnits",
      valueName: "Camera.Value",
      selectedName: "Camera.selected",
      selectedVal: getAQuoteHm.Camera?.selected,
      valueVal: getAQuoteHm.Camera?.Value,
      valueDef: getAQuoteHm.Camera?.Value,
    },
    {
      name: "Others",
      unitsVal: getAQuoteHm.Others?.NumberOfUnits,
      unitsDef: getAQuoteHm.Others?.NumberOfUnits,
      unitsName: "Others.NumberOfUnits",
      valueName: "Others.Value",
      selectedName: "Others.selected",
      selectedVal: getAQuoteHm.Others?.selected,
      valueVal: getAQuoteHm.Others?.Value,
      valueDef: getAQuoteHm.Others?.Value,
    },
  ];

  const handleDocsChange = (name: string, value: File | File[]) => {
    if (!Array.isArray(value)) dispatch(setExcelFile(value));
  };

  const removeDoc = (name: string) => {
    setBuildingData(undefined);
    dispatch(setExcelFile(undefined));
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const check = event.target.checked;
    const [nestedObjectKey, propertyKey] = name.split(".");

    setgetAQuoteHm((prev) => ({
      ...prev,
      [nestedObjectKey]: {
        //@ts-ignore
        ...prev[nestedObjectKey],
        [propertyKey]: check,
      },
    }));
  };

  const handleTypedValChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const [nestedObjectKey, propertyKey] = name.split(".");

    if (name === "PolicyDuration" || name === "ContactAddress") {
      setgetAQuoteHm((prev) => ({
        ...prev,
        [name]:
          name === "PolicyDuration" ? value.replace(/[^0-9.]/g, "") : value,
      }));
      return;
    }

    setgetAQuoteHm((prev) => ({
      ...prev,
      [nestedObjectKey]: {
        //@ts-ignore
        ...prev[nestedObjectKey],
        [propertyKey]:
          propertyKey === "Value" ? value.replace(/[^0-9.]/g, "") : value,
      },
    }));
  };

  const handleCreateQuoteSubmit = async (req: HomeOwnersGetQuoteReq) => {
    setLoading(true);
    const quoteInfoArr: QuoteInfoDetails[] = [];
    for (let key in req) {
      let value = req[key as keyof HomeOwnersGetQuoteReq];
      if (value && typeof value !== "string") {
        quoteInfoArr.push({
          itemName: key,
          itemContactAddress: req.ContactAddress ? req.ContactAddress : "",
          sumInsuredValue:
            parseFloat(value.Value) * parseFloat(value.NumberOfUnits),
          assetType: contentAssetTypeList
            .find((ass) => ass.item === key)!
            .type.toString(),
          category: contentCategoryList
            .find((ass) => ass.item === key)!
            .type.toString(),
        });
      }
    }

    const { data, error } = await homeOwnersCreateQuoteApi(
      {
        email: personalDetails.Email,
        phoneNumber: personalDetails.PhoneNumber!,
        firstName: personalDetails.FirstName,
        lastName: personalDetails.LastName,
        duration: Number(req.PolicyDuration),
        title: personalDetails.Title + 1,
        quoteInfoDetails: quoteInfoArr,
      },
      token
    );

    if (data) {
      dispatch(setHomeOwnerGetQuoteRes(data.data));
      setLoading(false);
      openSuccess(data.message ? data.message : "Quote created successfully");
      return;
    }

    if (error) {
      setLoading(false);
      openError(
        returnMsgFromAxiosError(error, "An error occurred creating quote")
      );
      return;
    }

    setLoading(false);
    openError("An error occurred creating quote");
  };

  const saveContinue = (
    polDuration: string,
    contact: string | undefined,
    dataJSON: ExpectedJSONFromExcel[],
    manualReq: HomeOwnersGetQuoteReq,
    excelFileI: File | undefined
  ) => {
    if (excelFileI !== undefined) {
      const reqObj = transformExpectedJSONToHomeOwnersReq(
        polDuration,
        contact,
        dataJSON
      );

      const { bol, field } = isPolicyDurationEmptyOrAllSelectedFalse(reqObj);
      if (bol === true && field === "pd") {
        openError("Please fill policy duration");
        return;
      }
      if (bol === true && field === "ca") {
        openError("Please fill contact address");
        return;
      }
      if (bol === true && field === "items") {
        openError("Please fill in at least one insurance item");
        return;
      }

      const { bool, key } = isAnySelectedTrueWithNotEmptyValues(reqObj);
      if (bool === false) {
        openError(`Please fill units and value for ${key}`);
        return;
      }
      dispatch(setHomeOwnersGetQuoteRequest(reqObj));
      handleCreateQuoteSubmit(reqObj);
    } else {
      const manualRequest = filterSelectedTrue(manualReq);
      const { bol, field } =
        isPolicyDurationEmptyOrAllSelectedFalse(manualRequest);
      if (bol === true && field === "pd") {
        openError("Please fill policy duration");
        return;
      }
      if (bol === true && field === "ca") {
        openError("Please fill contact address");
        return;
      }
      if (bol === true && field === "items") {
        openError("Please fill in at least one insurance item");
        return;
      }
      const { bool, key } = isAnySelectedTrueWithNotEmptyValues(manualRequest);
      if (bool === false) {
        openError(`Please fill units and value for ${key}`);
        return;
      }
      dispatch(setHomeOwnersGetQuoteRequest(manualRequest));
      handleCreateQuoteSubmit(manualRequest);
    }
  };

  const goBack = () => {
    dispatch(setRentStep(1));
  };

  const goNext = () => {
    if (HomeOwnerGetQuoteRes !== undefined) {
      dispatch(setRentStep(3));
    } else {
      openError("Please fill the form");
    }
  };

  const checkForOtherItems = (checkedJson: ExpectedJSONFromExcel[]) => {
    const othersObj = checkedJson.filter(
      (item) =>
        !itemsToBeDisplayed.includes(item.items.toLowerCase()) &&
        !contentItemsToBeDisplayed
          .map((item) => item.toLowerCase())
          .includes(item.items.toLowerCase())
    );
    const otherSum = othersObj.reduce(
      (acc: number, curr: ExpectedJSONFromExcel) => {
        return Number(acc) + Number(curr.value);
      },
      0
    );

    const otherUnit = othersObj.reduce(
      (acc: number, curr: ExpectedJSONFromExcel) => {
        return Number(acc) + Number(curr.units);
      },
      0
    );

    const hasOthers = otherSum > 0;

    return { otherSum, otherUnit, hasOthers };
  };

  const handleExcelJsonDisplay = (data: unknown) => {
    if (data && Array.isArray(data) && data.length > 0) {
      const newData = convertKeysToLowerCase(data);
      const oneObj = newData[0];
      const oneObjKeys = Object.keys(oneObj).map((key) => key.toLowerCase());

      if (
        (oneObjKeys && oneObjKeys.length && oneObjKeys.length !== 3) ||
        !oneObjKeys.every((key) => acceptedKeys.includes(key))
      ) {
        openError("Please use 'items', 'units' and 'value' as column headings");
        dispatch(setExcelFile(undefined));
        return;
      }

      if (
        newData &&
        newData.some(
          (data) => data.items.toLowerCase() === ("others" || "other")
        )
      ) {
        openError("Please specify items under other(s)");
        dispatch(setExcelFile(undefined));
        return;
      }

      dispatch(setDisplayExcelData(newData));
      if (newData && newData.length > 0) {
        setBuildingData(
          newData.find((data) => data.items.toLowerCase() === "building")
        );

        setNotBuildingData(
          newData.filter(
            (data) =>
              data.items.toLowerCase() !== "building" &&
              itemsToBeDisplayed.includes(data.items.toLowerCase())
          )
        );

        setContentData(
          newData.filter(
            (data) =>
              data.items.toLowerCase() !== "building" &&
              contentItemsToBeDisplayed
                .includes(data.items.toLowerCase())
          )
        );

        const { hasOthers, otherSum, otherUnit } = checkForOtherItems(newData);
        if (hasOthers === true) {
          setNotBuildingData((prev) => [
            ...prev,
            {
              items: "Others",
              value: otherSum.toString(),
              units: otherUnit.toString(),
            },
          ]);
        }
      }
    }
  };

  useEffect(() => {
    if (HomeOwnersGetQuoteRequest && HomeOwnersGetQuoteRequest.PolicyDuration) {
      setgetAQuoteHm(HomeOwnersGetQuoteRequest);
    }
  }, []);

  useEffect(() => {
    if (excelFile !== undefined) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const workBook = read(e.target?.result);
        const sheet = workBook.SheetNames;
        if (sheet && sheet.length > 0) {
          const data = utils.sheet_to_json(workBook.Sheets[sheet[0]]);
          handleExcelJsonDisplay(data);
        }
      };

      reader.readAsArrayBuffer(excelFile);
    }
  }, [excelFile]);

  return (
    <>
      <div className={styles.form}>
        <div className={styles.header}>
          <div className={styles.headerblock}></div>
          <div className={styles.headertext}>Policy Duration</div>
        </div>

        <span className={styles["light-text-hm"]}>
          Kindly input your preferred policy duration in months.
        </span>

        <div className={styles.columngrid}>
          <div className={styles.inputcolumn}>
            <span>
              Policy Duration<span className={styles.red}>*</span>(Months)
            </span>
            <input
              name="PolicyDuration"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuoteHm.PolicyDuration}
            />
          </div>
          <div className={styles["inputcolumn-65"]}>
            <span>
              Items' Contact Address<span className={styles.red}>*</span>
            </span>
            <input
              name="ContactAddress"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuoteHm.ContactAddress}
            />
          </div>
        </div>

        <div className={styles["second-column-hm"]}>
          <div className={styles.header}>
            <div className={styles.headerblock}></div>
            <div className={styles.headertext}>Insurance Items</div>
          </div>
          <span className={styles["light-text-hm-2"]}>
            To get a quote, you can choose an option below to upload an excel
            file of the items you wish to insure, or manually input them.
          </span>

          <div className={styles["upload-div-hm"]}>
            <div className={styles["upload-div-left"]}>
              <span className={styles["green-head-hm"]}>
                Upload an Excel file
              </span>

              <span className={styles["light-text-hm-3"]}>
                Only xlsx. files are allowed.
              </span>
              <span className={styles["light-text-hm-3"]}>
                File must have 3 columns; items, units and value (in Nigerian
                Naira).
              </span>
              <span className={styles["light-text-hm-3"]}>
                Large files might take longer to be processed.
              </span>
              <span className={styles["light-text-hm-3"]}>
                Merged multiple documents are not allowed.
              </span>
            </div>

            <UploadSingleButton
              removeDoc={removeDoc}
              docValue={excelFile}
              name=""
              buttonTxt="Upload Items"
              handleDocsChange={handleDocsChange}
              accept=".xls,.xlsx,.xlsm,.xlsb,.xltm,.xltx,.csv"
            />
          </div>

          {buildingData && excelFile ? (
            <>
              <span className={styles["category-head-hm"]}>
                Category A: Building (0.2% premium)
              </span>
              <div className={styles["category-hm"]}>
                <div className={styles["category-left"]}>
                  <div className={styles["category-left-inner"]}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="Building"
                            className={styles["category-hm-check-cont"]}
                            defaultChecked={true}
                          />
                        }
                        color="success"
                        label="Building"
                        defaultChecked={true}
                      />
                    </FormGroup>
                  </div>
                </div>
                <div className={styles["category-right"]}>
                  <div className={styles["category-right-inner"]}>
                    <div className={styles.columngrid}>
                      <div className={styles.inputcolumn}>
                        <span>Number of units</span>
                        <input
                          name="Building.NumberOfUnits"
                          disabled
                          className={styles.inputfield}
                          autoComplete="off"
                          value={buildingData.units}
                          // defaultValue={buildingData.units}
                        />
                      </div>
                      <div className={styles["inputcolumn-65"]}>
                        <span>Value Of Each Unit (NGN)</span>
                        <input
                          name="Building.Value"
                          disabled
                          className={styles.inputfield}
                          autoComplete="off"
                          value={
                            buildingData.value
                              ? formatAmount(parseFloat(buildingData.value))
                              : ""
                          }
                          // defaultValue={
                          //   buildingData.value
                          //     ? formatAmount(parseFloat(buildingData.value))
                          //     : ""
                          // }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {contentData && contentData.length > 0 && excelFile ? (
            <>
              <span className={styles["category-head-hm"]}>
                Category B: Building Content (0.5% premium)
              </span>

              <div className={styles["category-hm"]}>
                <div className={styles["category-left"]}>
                  {contentData.map((data) => (
                    <div
                      className={styles["category-left-inner"]}
                      key={data.items.charCodeAt(9)}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={data.items && capitalizeFirstLetter(data.items)}
                              value={true}
                              className={styles["category-hm-check-cont"]}
                              defaultChecked={true}
                            />
                          }
                          color="success"
                          defaultChecked={true}
                          label={data.items && capitalizeFirstLetter(data.items)}
                        />
                      </FormGroup>
                    </div>
                  ))}
                </div>
                <div className={styles["category-right"]}>
                  {contentData.map((data) => (
                    <div
                      className={styles["category-right-inner"]}
                      key={data.items.charCodeAt(9)}
                    >
                      <div className={styles.columngrid}>
                        <div className={styles.inputcolumn}>
                          <span>Number of units</span>
                          <input
                            name="NumberOfUnits"
                            disabled
                            className={styles.inputfield}
                            autoComplete="off"
                            value={data.units}
                            // defaultValue={data.units}
                          />
                        </div>
                        <div className={styles["inputcolumn-65"]}>
                          <span>Value Of Each Unit (NGN)</span>
                          <input
                            name="Value"
                            disabled
                            className={styles.inputfield}
                            autoComplete="off"
                            value={
                              data.value
                                ? formatAmount(parseFloat(data.value))
                                : ""
                            }
                            // defaultValue={
                            //   data.value
                            //     ? formatAmount(parseFloat(data.value))
                            //     : ""
                            // }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : null}

          {notBuildingData && notBuildingData.length > 0 && excelFile ? (
            <>
              <span className={styles["category-head-hm"]}>
                Category C: All Risk (3% premium)
              </span>

              <div className={styles["category-hm"]}>
                <div className={styles["category-left"]}>
                  {notBuildingData.map((data) => (
                    <div
                      className={styles["category-left-inner"]}
                      key={data.items.charCodeAt(9)}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={data.items && capitalizeFirstLetter(data.items)}
                              value={true}
                              className={styles["category-hm-check-cont"]}
                              defaultChecked={true}
                            />
                          }
                          color="success"
                          defaultChecked={true}
                          label={data.items && capitalizeFirstLetter(data.items)}
                        />
                      </FormGroup>
                    </div>
                  ))}
                </div>
                <div className={styles["category-right"]}>
                  {notBuildingData.map((data) => (
                    <div
                      className={styles["category-right-inner"]}
                      key={data.items.charCodeAt(9)}
                    >
                      <div className={styles.columngrid}>
                        <div className={styles.inputcolumn}>
                          <span>Number of units</span>
                          <input
                            name="NumberOfUnits"
                            disabled
                            className={styles.inputfield}
                            autoComplete="off"
                            value={data.units}
                            // defaultValue={data.units}
                          />
                        </div>
                        <div className={styles["inputcolumn-65"]}>
                          <span>Value Of Each Unit (NGN)</span>
                          <input
                            name="Value"
                            disabled
                            className={styles.inputfield}
                            autoComplete="off"
                            value={
                              data.value
                                ? formatAmount(parseFloat(data.value))
                                : ""
                            }
                            // defaultValue={
                            //   data.value
                            //     ? formatAmount(parseFloat(data.value))
                            //     : ""
                            // }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : null}

          {displayExcelData.length === 0 || excelFile === undefined ? (
            <>
              <span className={styles["category-head-hm-green"]}>
                Manually Input Insurance Items
              </span>
              <span className={styles["category-head-hm"]}>
                Category A: Building (0.2% premium)
              </span>
              <div className={styles["category-hm"]}>
                <div className={styles["category-left"]}>
                  <div className={styles["category-left-inner"]}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="Building.selected"
                            checked={getAQuoteHm.Building?.selected}
                            className={styles["category-hm-check-cont"]}
                            onChange={handleChange}
                          />
                        }
                        color="success"
                        label="Building"
                      />
                    </FormGroup>
                  </div>
                </div>
                <div className={styles["category-right"]}>
                  <div className={styles["category-right-inner"]}>
                    <div className={styles.columngrid}>
                      <div className={styles.inputcolumn}>
                        <span>Number of units</span>
                        <input
                          name="Building.NumberOfUnits"
                          onChange={handleTypedValChange}
                          className={styles.inputfield}
                          autoComplete="off"
                          value={getAQuoteHm.Building?.NumberOfUnits}
                          // defaultValue={getAQuoteHm.Building?.NumberOfUnits}
                        />
                      </div>
                      <div className={styles["inputcolumn-65"]}>
                        <span>Value Of Each Unit (NGN)</span>
                        <input
                          name="Building.Value"
                          onChange={handleTypedValChange}
                          className={styles.inputfield}
                          autoComplete="off"
                          value={
                            getAQuoteHm.Building?.Value
                              ? formatAmount(
                                  parseFloat(getAQuoteHm.Building.Value)
                                )
                              : ""
                          }
                          // defaultValue={
                          //   getAQuoteHm.Building?.Value
                          //     ? formatAmount(
                          //         parseFloat(getAQuoteHm.Building.Value)
                          //       )
                          //     : ""
                          // }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <span className={styles["category-head-hm"]}>
                Category B: Building Content (0.5% premium)
              </span>

              <div className={styles["category-hm"]}>
                <div className={styles["category-left"]}>
                  {sectionBArr.map((item) => (
                    <div
                      className={styles["category-left-inner"]}
                      key={item.name}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={item.selectedName}
                              value={item.selectedVal}
                              className={styles["category-hm-check-cont"]}
                              onChange={handleChange}
                            />
                          }
                          color="success"
                          label={item.name}
                        />
                      </FormGroup>
                    </div>
                  ))}
                </div>
                <div className={styles["category-right"]}>
                  {sectionBArr.map((item) => (
                    <div
                      className={styles["category-right-inner"]}
                      key={item.name}
                    >
                      <div className={styles.columngrid}>
                        <div className={styles.inputcolumn}>
                          <span>Number of units</span>
                          <input
                            name={item.unitsName}
                            onChange={handleTypedValChange}
                            className={styles.inputfield}
                            autoComplete="off"
                            value={item.unitsVal}
                            // defaultValue={item.unitsDef}
                          />
                        </div>
                        <div className={styles["inputcolumn-65"]}>
                          <span>Value Of Each Unit (NGN)</span>
                          <input
                            name={item.valueName}
                            onChange={handleTypedValChange}
                            className={styles.inputfield}
                            autoComplete="off"
                            value={
                              item.valueVal
                                ? formatAmount(parseFloat(item.valueVal))
                                : ""
                            }
                            // defaultValue={
                            //   item.valueDef
                            //     ? formatAmount(parseFloat(item.valueDef))
                            //     : ""
                            // }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>

              <span className={styles["category-head-hm"]}>
                Category C: All Risk (3% premium)
              </span>

              <div className={styles["category-hm"]}>
                <div className={styles["category-left"]}>
                  {sectionCArr.map((item) => (
                    <div
                      className={styles["category-left-inner"]}
                      key={item.name}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={item.selectedName}
                              value={item.selectedVal}
                              className={styles["category-hm-check-cont"]}
                              onChange={handleChange}
                            />
                          }
                          color="success"
                          label={item.name}
                        />
                      </FormGroup>
                    </div>
                  ))}
                </div>
                <div className={styles["category-right"]}>
                  {sectionCArr.map((item) => (
                    <div
                      className={styles["category-right-inner"]}
                      key={item.name}
                    >
                      <div className={styles.columngrid}>
                        <div className={styles.inputcolumn}>
                          <span>Number of units</span>
                          <input
                            name={item.unitsName}
                            onChange={handleTypedValChange}
                            className={styles.inputfield}
                            autoComplete="off"
                            value={item.unitsVal}
                            // defaultValue={item.unitsDef}
                          />
                        </div>
                        <div className={styles["inputcolumn-65"]}>
                          <span>Value Of Each Unit (NGN)</span>
                          <input
                            name={item.valueName}
                            onChange={handleTypedValChange}
                            className={styles.inputfield}
                            autoComplete="off"
                            value={
                              item.valueVal
                                ? formatAmount(parseFloat(item.valueVal))
                                : ""
                            }
                            // defaultValue={
                            //   item.valueDef
                            //     ? formatAmount(parseFloat(item.valueDef))
                            //     : ""
                            // }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : null}
        </div>

        {HomeOwnerGetQuoteRes === undefined ? (
          <div
            role="button"
            onClick={() =>
              saveContinue(
                getAQuoteHm.PolicyDuration,
                getAQuoteHm.ContactAddress,
                displayExcelData,
                getAQuoteHm,
                excelFile
              )
            }
            className={styles["get-a-quote-button"]}
          >
            <span>Get a Quote</span>
          </div>
        ) : (
          <>
            <div className={styles["total-insured-value"]}>
              <span className={styles["total-insured-value-p"]}>
                Total Value of Insured Items
              </span>
              <span className={styles["total-insured-value-span"]}>
                {HomeOwnerGetQuoteRes.totalSumInsured
                  ? `₦${formatAmount(HomeOwnerGetQuoteRes.totalSumInsured)}`
                  : ""}
              </span>
            </div>
            <div className={styles["quote-box"]}>
              <p>Quote</p>
              <span>Annual Payable Premium</span>
              <div className={styles.amount}>
                {HomeOwnerGetQuoteRes.totalPremium
                  ? `₦${formatAmount(HomeOwnerGetQuoteRes.totalPremium)}`
                  : ""}
              </div>
            </div>
          </>
        )}

        <div className={styles["container-bottom-butt"]}>
          <span onClick={() => goBack()} role="button">
            Back
          </span>

          {HomeOwnerGetQuoteRes !== undefined && (
            <div
              className={styles["continue-butt"]}
              role="button"
              onClick={() => goNext()}
            >
              <span>Save & Continue</span>
            </div>
          )}
        </div>
      </div>
    </>
  );
};

export default GetAQuote;
