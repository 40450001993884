import { User, UserInfo } from "./User";

export type Amenity =
  | "Equipped Kitchen"
  | "Gym"
  | "Laundry"
  | "Media Room"
  | "Backyard"
  | "Pool"
  | "Electricity"
  | "Electricity"
  | "Water Supply"
  | "Air Conditioning"
  | "Washing Machine"
  | "WIFI";

export type PropertyType =
  | "Residential (General)"
  | "Residential (Student Package)"
  | "Commercial"
  | "Land"
  | "Flats";

export type PropertyStatus =
  | "Rent"
  | "Sale"
  | "Sale"
  | "Rent To Own"
  | "RentToOwn"
  | "Mortgage";


  export enum AgentPropertyStatus {
    "Rent" = 0,
    "Sale" = 1,
    "Rent To Own" = 2,
    "Mortgage" = 3,
  }

export type ParkingLot = "No" | "Yes";
export type RentDuration = "Annual" | "Monthly";

export enum Bathrooms {
  Zero = 0,
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6,
}
export enum Bedrooms {
  Zero = 0,
  One = 1,
  Two = 2,
  Three = 3,
  Four = 4,
  Five = 5,
  Six = 6,
}

export interface AmenityWithCount {
  id?: number;
  amenity: Amenity | string;
  number: number;
}

export interface Attachment {
  id?: number;
  alt?: string;
  imageUrl: string;
}

export interface Comment {
  name?: string;
  comment?: string;
  dateAdded?: Date;
  rating?: number;
}

/**
 * Add:
 * - afterlabel
 * - rooms
 * - availbalefrom date
 * - video
 */

export interface EditProperty {
  title: string;
  description: string;
  price: number;
  otherPrices: number;
  status: number;
  type: number;
  size: number;
  address: string;
  country: number;
  state: number;
  city: string;
  neighbourhood: string;
  zip: string;
  bedrooms: number;
  bathrooms: number;
  duration: number;
  parkingLot: number;
  amenities: [
    {
      amenity: number;
      number: number;
    }
  ];
}


export interface Property {
  id?: number;
  title: string;
  description: string;
  price: number;
  otherPrices?: number;
  totalPrice?: number;
  status: PropertyStatus;
  type: PropertyType;
  size: number;
  address: string;
  state: string;
  city: string;
  country?: string;
  neighbourhood: string;
  longitude?: number;
  latitude?: number;
  zip?: string;
  bedrooms?: number | string;
  bathrooms?: number | string;
  availability?: boolean;
  dateAdded?: Date;
  user: User;
  userId: string;
  rating?: number;
  parkingLot?: ParkingLot;
  duration?: RentDuration;
  amenities?: AmenityWithCount[];
  attachments?: Attachment[];
  comments?: Comment[];
  isEnabled?: boolean;
  isFeatured?: boolean;
  realEstateName?: string;
  isCompleted?: boolean;
  isInstallmental?: boolean;
  downPayment?: number;
  paymentDuration?: number;
}

export interface PropertyRequest {
  title?: string;
  description?: string;
  price?: number;
  otherPrices?: number;
  totalPrice?: number;
  status?: PropertyStatus | string;
  type?: PropertyType | string;
  size?: number;
  address?: string;
  country?: string;
  state?: string;
  city?: string;
  neighbourhood?: string;
  longitude?: number;
  latitude?: number;
  zip?: string;
  bedrooms?: number | string;
  bathrooms?: number | string;
  availability?: boolean;
  dateAdded?: Date;
  parkingLot?: ParkingLot;
  duration?: RentDuration;
  amenities?: AmenityWithCount[];
  attachments?: Attachment[];
  isEnabled?: boolean;
  isFeatured?: boolean;
  realEstateName?: string;
  isCompleted?: boolean;
  isInstallmental?: boolean;
  downPayment?: number;
  paymentDuration?: number;
}

export interface Comment {
  username?: string;
  comment?: string;
  rating?: number;
  dateAdded?: Date;
}

export interface PropertyPayment {
  amount: string;
  narration: string;
  property_id: number;
  destination_user_id: string;
}

//   source_user_id: "6bc3a491-8703-41e1-953c-33435bd22113",
//   destination_user_id: "dac2e307-5f2a-4efc-840e-22db6858821e"

export interface PropertyPaymentResponse {
  succeeded: boolean;
  message: string;
  errors: null;
  data: any;
  meta: any;
  rentBalance: number;
  propertyPurchaseBalance: number;
  generalBalance: number;
  monthlyBillsBalance: number;
  overallBalance: number;
}

export interface MakePropertyFeaturedReq {
  propertyId: number;
  token: string;
  pin: string;
}

export interface MakePropertyFeaturedRes {
  succeeded: boolean;
  message: string;
  errors: null;
  data: {
    transactionReference: string;
  };
  meta: any;
  rentBalance: number;
  propertyPurchaseBalance: number;
  generalBalance: number;
  monthlyBillsBalance: number;
  overallBalance: number;
}
export interface ApplyMortgage {
  amountPerMonth: undefined | string;
  downPayment: undefined | string;
  willUseRSA: undefined | boolean;
  RSANo: undefined | string;
  threeYearsEmployed: boolean;
  age: string
}

export interface ApplyMortgageReq {
  propertyId: number;
  monthlyIncome: number;
  downPaymentFromPension: boolean;
  downPayment: number;
  hasAccessPension: boolean;
  rsaPin?: string;
  annualNetIncome: number;
  pensionBalance: number;
  salaryStructure: number;
  repaymentPeriod: number;
  confirmedStaff: boolean;
  threeYearsEmployed: boolean;
  age: string | number
}

export interface ApplyMortgageRes {
  succeeded: boolean;
  message: string;
  errors: any;
  data: any;
  meta: any;
  rentBalance: number;
  propertyPurchaseBalance: number;
  generalBalance: number;
  monthlyBillsBalance: number;
  overallBalance: number;
}

export interface ApplyPensionsReq {
  title: number;
  gender: number;
  maritalStatus: number;
  firstName: string;
  lastName: string;
  middleName: string;
  dateOfBirth: Date;
  state: number;
  address: string;
  phoneNumber: string;
  email: string;
  employerName: string;
  employerAddress: string;
  currentPFA: string;
  rsapin: string;
  hasRSAPIN: boolean;
  Signature: string;
}

export enum PaymentDuration {
  one = "6 months",
  two = "12 months",
  three = "18 months",
}

export interface MortageApplicant {
  id: number;
  user: User;
  properties: Property;
  createdAt: Date;
  updatedAt: Date;
  monthlyIncome: number;
  annualNetIncome: number;
  pensionBalance: number;
  estimatedMonthlyPayment: number;
  downPayment: number;
  downPaymentFromPension: boolean;
  hasAccessPension: boolean;
  rsaPin: string;
  salaryStructure: number;
  repaymentPeriod: number;
  confirmedStaff: boolean;
  threeYearsEmployed: boolean;
  attendedTo: boolean;
  age: number
}

export interface ImageBlobFormat {
  uri: string;
  name: string;
  filename: string;
  type: string;
}
