import React, { SetStateAction } from "react";
import styles from "./addlv.module.scss";
import { Button } from "@mui/material";
import { useHistory } from "react-router";

interface IsFeaturedProps {
  closeModal: () => void;
  makePropertyFeaturedCall: (id: number) => Promise<void>;
  id: number;
  pin: string;
  setPin: React.Dispatch<SetStateAction<string>>;
}

const IsFeaturedProperty = ({
  closeModal,
  makePropertyFeaturedCall,
  id,
  pin,
  setPin,
}: IsFeaturedProps) => {
  const history = useHistory();
  const handleRejection = () => {
    closeModal();
    history.push("/properties");
  };

  return (
    <div className={styles.container}>
      
      <div className={styles.headerCancel}>
        <span>You will be notified via email on the update of your listing.</span>
        
        <div className="icon-cancel" onClick={handleRejection}></div>
      </div>
    

      <div className={styles.body}>
      <p style={{color: 'black'}}>
          Do you want your property featured and seen before other properties ?
        </p>
        <span style={{ textAlign: "center" }}>
          A non refundable fee of ₦ 5,000 would be deducted from your wallet for
          this service
        </span>
      </div>

      <input
        value={pin}
        onChange={(e) => setPin(e.target.value)}
        style={{
          width: "50%",
          height: "17%",
          marginLeft: "1.5em",
          border: "0.5px solid #CCCCCC",
          padding: "1em",
        }}
        placeholder="Enter Pin"
      />

      <div className={styles.buttons}>
        <Button
          type="button"
          variant="contained"
          sx={{
            textAlign: "center",
            backgroundColor: "var(--ltn__secondary-color)",
            "&:hover": {
              backgroundColor: "var(--ltn__paragraph-color)",
            },
            // paddingTop: "1.8em",
            height: "3em",
            display: "flex",
            alignItems: "center",
            marginTop: "0.5em",
            justifyContent: "center",
          }}
          onClick={() => makePropertyFeaturedCall(id)}
        >
          Yes
        </Button>
        <Button
          type="button"
          variant="contained"
          sx={{
            textAlign: "center",
            backgroundColor: "#FF7675",
            "&:hover": {
              backgroundColor: "var(--ltn__paragraph-color)",
            },
            // paddingTop: "1.8em",
            height: "3em",
            display: "flex",
            alignItems: "center",
            marginTop: "0.5em",
            justifyContent: "center",
          }}
          onClick={() => handleRejection()}
        >
          No
        </Button>
      </div>
    </div>
  );
};

export default IsFeaturedProperty;
