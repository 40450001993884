import Layout from "../../components/Layout";
import ScrollToTop from "../../components/ScrollToTop";
import SessionCheck from "../../components/SessionCheck";
import NavHeader from "../../components/NavHeader";
import SimilarProperties from '../../sections/SimilarProperties/index'

const Index = () => {
  return (
    <Layout>
      <ScrollToTop />
      <SessionCheck shouldRedirect />
      <NavHeader pageTitle="Similar Properties" routeName="Similar Properties" />
      <SimilarProperties />
    </Layout>
  );
};

export default Index;
