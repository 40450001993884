import Layout from "../../components/Layout";
import NavHeader from "../../components/NavHeader";
import ScrollToTop from "../../components/ScrollToTop";
import SessionCheck from "../../components/SessionCheck";
import AgentProperties from "../../sections/AgentProperties";

const Index = () => {
  return (
    <Layout>
      <ScrollToTop />
      <SessionCheck shouldRedirect />
      <NavHeader pageTitle="Agent Listings" routeName="Agent Properties" />
      <AgentProperties/>
    </Layout>
  );
};

export default Index;