import React, { SetStateAction } from "react";
import { Link } from "react-router-dom";
import { PropertyStatus } from "../../../types/Property";
import styles from "./lister.module.scss";
import { useHistory } from "react-router";
import ToastContext from "../../../contexts/ToastContext";
import { getWalletDetails } from "../../../swr/wallet";
import { log } from "console";

export interface ListerWidgetProps {
  listerId?: string;
  history: any;
}

const ListerWidget: React.FC<ListerWidgetProps> = ({ listerId, history }) => {
  const handleLister = () => {
    history.push("/similarproperties");
    if (listerId) {
      localStorage.setItem("listerId", listerId);
    }
  };
  return (
    <div className="widget">
      {/* <h4 className="ltn__widget-title ltn__widget-title-border-2">Make Payment</h4> */}
      <div className={styles.buttonGroup}>
        <button
          className="btn theme-btn-1"
          disabled={!listerId}
          onClick={() => handleLister()}
        >
          {"View Similar Properties"}
        </button>
        {/* <Link to="/loan"><button type="submit" className="btn theme-btn-1">Apply for loan</button></Link> */}
        {/* {<button type="submit" className="btn theme-btn-1" onClick={onDelete}>Delete Listing</button>} */}
      </div>
    </div>
  );
};

export default ListerWidget;
