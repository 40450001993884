import { useEffect, useState, useContext } from 'react';
import LoginAccount from '../../../components/Form/LoginAccount';
import FormComp from '../../../components/Form';
import useFetch from '../../../hooks/useFetch';
import useForm from '../../../hooks/useForm';
import backendRoutes from '../../../utils/backendRoutes';
import { useHistory, useLocation } from 'react-router-dom';
import { setError, setUser, setToken, User } from '../../../state/reducers/authSlice';
import { useAppDispatch } from '../../../hooks/useSelector';
import LoadingScreen from '../../../components/LoadingScreen';
import SessionContext from '../../../contexts/SessionContext';
import ToastContext from '../../../contexts/ToastContext';
import { useQuery } from '../../../hooks/useQuery';
import { useStateContext } from '../../../contexts/StateContext';

const SignIn = () => {
    const dispatch = useAppDispatch();
    const [loginSuccess, setLoginSuccess] = useState<boolean>(false);
    const { formValues, handleChange } = useForm({ email: '', password: '' });
    const [loginPostData, loginRes, loginErr, isLoading] = useFetch();
    const { push, goBack, location } = useHistory();
    const query = useQuery();

    const {setEmail} = useStateContext()

    // Context
    const { setIsExpired } = useContext(SessionContext);
    const { openError } = useContext(ToastContext);

    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        setEmail(formValues.email)
        localStorage.setItem("email", formValues.email)
        loginUser(formValues);
    };

    const loginUser = (payload: { [key: string]: string }) => {
        setLoginSuccess(false);
        loginPostData({
            url: backendRoutes.authenticate,
            data: payload,
            method: 'POST',
        });
    };

    const setLocalStorageToken = (token: string) => {
        localStorage.setItem('token', token);
        dispatch(setToken(token));
    };

    const setLoggedInUser = (user: User, token: string) => {
        setLoginSuccess(true);
        setLocalStorageToken(token);
        setIsExpired(false);
        dispatch(setUser({ ...user }));
    };

    useEffect(() => {
        if (loginRes) {
            if (!loginErr && loginRes?.succeeded) {
                setLoggedInUser(loginRes.data, loginRes.data.jwToken);
                localStorage.setItem('id', loginRes.data.id)
                query.get('redirect') ? push(`/${query.get('redirect')}`) : push('/');
                localStorage.setItem('id', loginRes.data.id);
            } else {
                setLoginSuccess(false);
                openError(loginErr.response?.data.Message|| 'An unknown error occured while signing you in');
                dispatch(setError());
            }
        }
    }, [loginRes]);

    return (
        <>
        {/** Added Loading screen */}
        {isLoading && <LoadingScreen/>}
        {/* <FormComp
            title="Login"
            textContent=""
            paddingSize="pb-65"
            pageName="Login"
            pageTitle="Login Page"
        > */}
            {loginSuccess}
            <LoginAccount formValues={formValues} handleChange={handleChange} onSubmit={handleSubmit} link="/signup" loading={isLoading} userName={''} />
        {/* </FormComp> */}
        </>
    );
};

export default SignIn;
