import React from 'react'
import styles from '../Lister/lister.module.scss'

export interface ReportWidgetProps {
    handleModal: () => void
}

const ReportWidget: React.FC<ReportWidgetProps> = ({handleModal}) => {
  return (
    <div className="widget">
      {/* <h4 className="ltn__widget-title ltn__widget-title-border-2">Make Payment</h4> */}
      <div className={styles.buttonGroup}>
        <button
          className="btn theme-btn-1"
        //   disabled={!listerId}
          onClick={() => handleModal()}
        >
          {"Report Listing"}
        </button>
      </div>
    </div>
  )
}

export default ReportWidget