import React, { useEffect, useState } from 'react';
import styles from './ImageSliderSecond.module.scss'; // Import your CSS or use styled-components
import first from '../../assets/firstImageSlide.png'
import second from '../../assets/secondImageSlide.png'
import third from '../../assets/thirdImageSlide.png'
interface ImageSliderProps {
  images?: string[];
}

const ImageSliderSeecond: React.FC<ImageSliderProps> = ({  }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const images = [
    first,
    second,
    third
  ]

  let interval = 3000

  const goToPrev = () => {
    setCurrentIndex((prevIndex) => (prevIndex === 0 ? images.length - 1 : prevIndex - 1));
  };

  const goToNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex === images.length - 1 ? 0 : prevIndex + 1));
  };

  useEffect(() => {
    const sliderInterval = setInterval(() => {
      // Calculate the next index, and loop back to 0 if at the end
      const nextIndex = (currentIndex + 1) % images.length;
      setCurrentIndex(nextIndex);
    }, interval);

    // Clean up the interval when the component unmounts
    return () => clearInterval(sliderInterval);
  }, [currentIndex, images.length, interval]);

  return (
    <div className={styles.imageslider}>
      <img className={styles.sliderimage} src={images[currentIndex]} alt={`Image ${currentIndex}`} />
     
    </div>
  );
};

export default ImageSliderSeecond;
