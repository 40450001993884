import { Property } from "../types/Property";

export function formatAmount(amount: string): string {
    
    
    let formattedString: string = `${amount}`;
    let decimals = '';

    if (formattedString.includes('.')) {
        let indexOfDot = formattedString.indexOf('.');
        decimals = formattedString.slice(indexOfDot);
        formattedString = formattedString.slice(0,indexOfDot);
    }
    
    formattedString = formattedString.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
    return formattedString + decimals;
}

export const genMsgId = () => {
    let msg_id = Math.floor(Math.random() * 1000000000)
    return msg_id.toString()
  }

export const genTransactionRef = () => {
    let ref = Math.floor(Math.random() * 3000000000000)
    return ref.toString()
  }

export function formatCurrency(amount: string): string {

    amount = amount.replaceAll(',', '');
    
    if (amount.length === 1) {
        return "0.0" + amount;
    }

    if (amount.length > 1) {
        let pointIndex = amount.indexOf('.');
        let charBeforePoint = amount[pointIndex -1];

        if (charBeforePoint ==='0' && amount[0] === '0') {
            amount = amount.slice(pointIndex, amount.length);
        }
        let stringWithoutPoint = amount.replace('.', '');
        let insertionIndex = stringWithoutPoint.length - 2;
        let result = formatAmount(stringWithoutPoint.slice(0, insertionIndex)) + '.' + stringWithoutPoint.slice(-2);

        return result;
    }

    return amount;
}

export function formatDate(dateInput:Date) {
    const months = [
        "January", "February", "March", "April", "May",
        "June", "July", "August", "September", "October",
        "November", "December"
    ];

    let month = months[dateInput.getMonth()];
    let date = dateInput.getDate();
    let year = dateInput.getFullYear();
    let formatedDate = `${month} ${date}, ${year}`;

    return formatedDate;
}

export function errorMessage(error: any, message: string) {
    if (error && error.message && error.message === 'Network Error') {
        return 'No internet connection'
    }

    return message;
}

export function formatRoom(input: string) {
    let values: { [index: string]: any } = {
        One: 1, Two: 2, Three: 3, Four: 4, Five: 5, Six: 6
    }
    return values[input];
}

export function countPropertyType(type: string, properties?: Property[]){
    let filteredProperties = properties?.filter((p)=>{
        return p.type === type;
    });
    return filteredProperties?.length;
}

export function countStatusType(status: string, properties?: Property[]){
    let processedStatus= status.replace(/\s/g, '');
    let filteredProperties = properties?.filter((p)=>{
        return p.status === processedStatus;
    });
    return filteredProperties?.length;
}

// Descending order
export function ratingComparator(a: Property, b: Property) {
    if (a.rating && b.rating) {
        if (a.rating > b.rating) {
            return -1;
        }
        else if (a.rating < b.rating){
            return 1;
        }
        else{
            return 0;
        }
    }
    else{
        return 0;
    }
}

// Status simulator
export interface VerificationStatus{
    status: string;
    label: string;
    icon?: string;
    tag?: string
}
export function simulateStatus(num: number) {
    let status: VerificationStatus[] = [
        { 
            status: 'amber', 
            label: 'Property not verified',
            icon: 'fa fa-exclamation',
            tag: 'Not verified' 
        },

        { 
            status: 'green', 
            label: 'Property verified',
            icon: 'fa fa-check',
            tag: 'Verified' 
        },
    ]

    return status[num];
}

export const formatPropertyStatus = (status: number)  => {
    if(status === 0){
        return 'Rent'
    } else if (status === 1) {
        return 'Sale'
    } else if (status === 2) {
        return 'Rent To Own'
    } else if (status === 3) {
        return 'Mortgage'
    }
}