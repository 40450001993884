import Axios, { AxiosError, AxiosResponse } from 'axios'
import useSwr, { useSWRConfig } from 'swr'
import {
  Rating,
  RatingAddResponse,
  RatingRequest,
  RatingResponse,
  RatingPropertyResponse,
} from '../types/Rating'
import api from '../axios'

const baseUrl = process.env.REACT_APP_BASE_URL || 'https://api.homebuddy.ng'
// const fetcher = (url: string) => Axios.get(baseUrl + url).then((response)=> response.data);
// const { mutate } = useSWRConfig()

// export const useRating = (id?: string | number) =>{
//
//     // const config = {
//     //     timeout: 30000 // 30 seconds
//     // }
//     const fetcher = (url: string) =>
//     Axios.get(baseUrl + url).then((response: AxiosResponse<RatingResponse>)=> response.data);
//     const { data, error } = useSwr(`/api/Ratings/get-rating?id=${id}`, fetcher);
//     return {
//         data: data,
//         isLoading: !error && !data,
//         isError: error
//     }
// }
export const useRating = (id?: string | number) => {
  const shouldFetch = id !== undefined
  const fetcher = (url: string) =>
    Axios.get(baseUrl + url).then(
      (response: AxiosResponse<RatingResponse>) => response.data
    )
  const { data, error } = useSwr(
    shouldFetch ? `/api/Ratings/get-rating?id=${id}` : null,
    fetcher
  )
  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  }
}

export const usePropertyRating = (
  propertyId: string | number,
  pageNumber: number,
  pageSize: number,
  includeCount: boolean
) => {
  const fetcher = (url: string) =>
    Axios.get(baseUrl + url).then(
      (response: AxiosResponse<RatingPropertyResponse>) => response.data
    )

  const queryString = `id=${propertyId}&pageNumber=${pageNumber}&pageSize=${pageSize}&includeCount=${includeCount}`

  const { data, error } = useSwr(
    `/api/Ratings/get-rating-by-property?${queryString}`,
    fetcher
  )

  return {
    data: data,
    isLoading: !error && !data,
    isError: error,
  }
}

interface AddRatingResult {
  data?: RatingAddResponse
  error?: any
}

export const addRating = (request: RatingRequest, token?: string) => {
  const willAddRating = new Promise<AddRatingResult>((resolve) => {
    api.post('/api/Ratings/add-rating', request, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response: AxiosResponse<RatingAddResponse>) => {
        resolve({ data: response.data })
      })
      .catch((err: AxiosError | any) => {
        resolve({ error: err })
      })
  })

  return willAddRating
}

// export async function addRating(rating: Rating, token?: string) {
//     // update the local data immediately, but disable the revalidation
//     mutate(`/api/Ratings/get-rating/${rating.propertiesId}`, rating, false)

//     // send a request to the API to update the source
//     await Axios.post(baseUrl + "/api/Ratings/get-rating", rating,
//     {headers : {"Authorization": `Bearer ${token}`}})

//     // trigger a revalidation (refetch) to make sure our local data is correct
//     mutate(`/api/Ratings/get-rating/${rating.propertiesId}`)
// }
