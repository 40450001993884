import React from "react";
import styles from "./household.module.scss";
import firstLayerImg from "../../../assets/pexels-andrea.png";
import firstLayerImgTop from "../../../assets/Pattern_Pillar_1.jpg";
import firstLayerImgBottom from "../../../assets/Pattern_Roof.jpg";
import rentSecond1 from "../../../assets/rent-second-img1.jpg";
import rentSecond2 from "../../../assets/rent-second-img2.jpg";
import rentSecond3 from "../../../assets/rent-second-img3.jpg";
import rentBenefitImg1 from "../../../assets/rent-benefit-img1.jpg";
import rentBenefitImg2 from "../../../assets/rent-benefit-img2.jpg";
import rentBenefitImg3 from "../../../assets/rent-benefits-img3.jpg";
import rentBenefitImg4 from "../../../assets/rent-benefits-img4.jpg";
import { useHistory } from "react-router";

type Coverage = {
  img: any;
  head: string;
  text: string[];
};

type Benefit = {
  img: any;
  text: string;
};

const coverageArr: Coverage[] = [
  {
    img: rentSecond1,
    head: "Legal Expenses",
    text: [
      " Fire, Lightning, Explosion, Aircraft damage",
      "Impact Damage by Road Vehicles/Animals not belonging to or under the control of the insured",
      "Bursting or Overflowing of Water Tanks, Apparatus or Pipes,",
    ],
  },
  {
    img: rentSecond2,
    head: "Bushfire, Riot and Strike",
    text: [
      "Bushfire, Riot, and Strike, Malicious damage, Tornado, Flood, Earthquake",
      "Damage/theft to moveable items like laptops, phones, etc",
      "Legal liabilities to third parties other than family members.",
    ],
  },
  {
    img: rentSecond3,
    head: "Alternative Habitation Cost",
    text: [
      "Theft of household items (i.e. furniture and other belongings)",
      "Theft of valuables, jewelry, and cash kept in safe",
      "Cost of alternative temporary accommodation should the house becomes inhabitable because of fire or flood damage.",
    ],
  },
];

const benefitsArr: Benefit[] = [
  {
    img: rentBenefitImg1,
    text: "Cover up to N500,000.00 in rental income per month for 6 months.",
  },
  {
    img: rentBenefitImg2,
    text: "Up to 3 months rental cover following eviction at 75% of monthly rent.",
  },
  {
    img: rentBenefitImg3,
    text: "Full landlord legal expenses up to N100,000 (including eviction costs).",
  },
  {
    img: rentBenefitImg4,
    text: "All tenant types are considered.",
  },
];

const returnImgBg = (num: number) => {
  if (num === 0) {
    return "#FFFEFA";
  } else if (num === 1) {
    return "#FDF8F6";
  } else {
    return "#F6FDF6";
  }
};

const returnTxtBg = (num: number) => {
  if (num === 0) {
    return "#FDF5D8";
  } else if (num === 1) {
    return "#FAE9E5";
  } else {
    return "#E5FAE5";
  }
};

const HouseHold = () => {
  const history = useHistory();
  const goToApplication = () => {
    history.push("/insurance/requestandbuy");
  };
  return (
    <div className={styles["home-inner"]}>
      <div className={styles["first-layer"]}>
        <div className={styles["first-layer-texts"]}>
          <span className={styles["first-layer-texts-para"]}>
            Household Insurance
          </span>

          <span className={styles["first-layer-texts-span"]}>
            Our Household Insurance policy offers protection for your belongings
            within the building against fire, lightning, explosion, theft and
            damage from vehicles or animals not owned or controlled by you.
          </span>

          <span className={styles["first-layer-texts-span"]}>
            Rest assured knowing your housegold items are covered against these
            potential risks.
          </span>
        </div>

        <div className={styles["first-layer-img"]}>
          <img src={firstLayerImg} className={styles["first-layer-image1"]} />
          <div className={styles["first-layer-image-top"]}>
            <img
              src={firstLayerImgTop}
              className={styles["first-layer-image"]}
            />
          </div>

          <div className={styles["first-layer-image-bottom"]}>
            <img
              src={firstLayerImgBottom}
              className={styles["first-layer-image"]}
            />
          </div>
        </div>
      </div>

      <div className={styles["coverage-cont"]}>
        <span className={styles["rent-header"]}>What It Covers</span>

        {coverageArr.map((cover, index) => (
          <div className={styles["coverage"]} key={cover.head}>
            <div
              className={styles["coverage-img-cont"]}
              style={{ backgroundColor: returnImgBg(index) }}
            >
              <img
                src={cover.img}
                alt={cover.head}
                className={styles["coverage-img"]}
              />
            </div>
            <div
              className={styles["coverage-cont-text"]}
              style={{ backgroundColor: returnTxtBg(index) }}
            >
              <div className={styles["coverage-li-container"]}>
                <div className={styles["coverage-cont-text-inner"]}>
                  {cover.text.map((item, index) => (
                    <div
                      className={styles["coverage-cont-text-inner-each"]}
                      key={index}
                    >
                      <div className={styles["coverage-dot"]}></div>
                      <div
                        className={styles["coverage-cont-text-inner-each-li"]}
                      >
                        {item}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <span className={styles["coverage-cont-text-p"]}>
                {cover.head}
              </span>
              <span className={styles["coverage-cont-text-span"]}>
                {cover.text}
              </span> */}
            </div>
          </div>
        ))}

        <div
          className={styles["black-but"]}
          role="button"
          onClick={() => goToApplication()}
        >
          Request & Buy
        </div>
      </div>

      {/* <div className={styles["benefits"]}>
        <div className={styles["benefits-header"]}>Benefits</div>
        <div className={styles["benefits-box-cont"]}>
          <div className={styles["vertical-cross"]}></div>
          <div className={styles["horizontal-cross"]}></div>
          {benefitsArr.map((benefit) => (
            <div
              className={styles["benefits-box"]}
              key={benefit.text.slice(0, 5)}
            >
              <img
                src={benefit.img}
                alt={benefit.text.split(" ")[0]}
                className={styles["benefits-img"]}
              />
              <span className={styles["benefits-text"]}>{benefit.text}</span>
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default HouseHold;
