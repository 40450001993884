import React, { useContext, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../../../state/store";
import { ActionCreatorWithPayload } from "@reduxjs/toolkit";
import { AssetTypeList, assetTypeLists, categoryLists, GetQuoteCategoryPair, HouseHoldGetQuoteReq, QuoteInfoDetail, RentDropDown } from "../../../../types/Insurance";
import styles from "./household-form.module.scss";
import { useDispatch } from "react-redux";
import {
    setDisplayExcelData,
  setExcelFile,
  setHouseHoldGetQuoteRequest,
  setHouseHoldGetQuoteRes,
} from "../../../../state/reducers/insuranceSlice";
import ToastContext from "../../../../contexts/ToastContext";
import { convertKeysToLowerCase, formatAmount, returnMsgFromAxiosError } from "../../../../utils/generalUtils";
import greenBlock from "../../../../assets/blockIcon.svg";
import UploadSingleButton from "../../../../components/Insurance/UploadDocs/UploadSingleButton";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import Checkbox from "@mui/material/Checkbox";
import { utils, read } from "xlsx";
import LoadingContext from "../../../../contexts/LoadingContext";
import { householdCreateQuoteApi } from "../../../../swr/insurance";

// const coverageArr = ["3 months", "6 months"];

const acceptedKeys = ["items", "units", "value"];

const itemsToBeDisplayed = [
    "building",
    "airconditioner",
    "television",
    "refrigerator",
    "freezer",
    "hometheatre",
    "table",
    "gascooker",
    "inverter",
    "bed",
    "waterdispenser",
    "washingmachine",
    "livingroom",
    "kitchencabinets",
    // "shoes",
    // "bags",
    // "clothing",
    // "standingfan",
    // "laptop",
    // "phone",
    // "jewelries",
    // "wristwatch",
    // "camera",
    "other",
    // "others"
  ];

  const itemsToBeDisplayed2 =[
    "shoes",
    "bags",
    "clothing",
    "standingfan",
    "laptop",
    "phone",
    "jewelries",
    "wristwatch",
    "camera",
  ]

export type ExpectedJSONFromExcel = {
  items: string;
  units: string;
  value: string;
};

export const transformExpectedJSONToHouseHoldReq = (
    policyDuration: string,
    contact: string | undefined,
    data: ExpectedJSONFromExcel[]
  ): HouseHoldGetQuoteReq => {
    const houseHoldReq: HouseHoldGetQuoteReq = {
      PolicyDuration: policyDuration,
      ContactAddress: contact,
    };
  
    for (const item of data) {
      const key = item.items;
      const value = parseInt(item.value);
      const units = parseInt(item.units);
  
      if (
        key === "Building" ||
        key === "AirConditioner" ||
        key === "Television" ||
        key === "Refrigerator" ||
        key === "Freezer" ||
        key === "HomeTheatre" ||
        key === "Table" ||
        key === "GasCooker" ||
        key === "Inverter" ||
        key === "Bed" ||
        key === "WaterDispenser" ||
        key === "WashingMachine" ||
        key === "LivingRoom" ||
        key === "KitchenCabinets" ||
        key === "Shoes" ||
        key === "Bags" ||
        key === "Clothing" ||
        key === "StandingFan" ||
        key === "Camera" ||
        key === "Laptop" ||
        key === "Phone" ||
        key === "Jewelries" ||
        key === "WristWatch" ||
        key === "Other"
      ) {
        houseHoldReq[key] = {
          NumberOfUnits: units.toString(),
          Value: value.toString(),
          selected: true,
        };
      } else if (key !== "Others") {
        // Unexpected key found, initialize Others category
        houseHoldReq.Others = houseHoldReq.Others || {
          NumberOfUnits: "0",
          Value: "0",
          selected: true,
        };
        houseHoldReq.Others.NumberOfUnits = (
          parseInt(houseHoldReq.Others.NumberOfUnits) + units
        ).toString();
        houseHoldReq.Others.Value = (
          parseInt(houseHoldReq.Others.Value) + value
        ).toString();
      }
    }
  
    // Remove Others category if it's empty
    if (Object.keys(houseHoldReq.Others || {}).length === 0) {
      delete houseHoldReq.Others;
    }
  
    return houseHoldReq;
  };
  
  const isPolicyDurationEmptyOrAllSelectedFalse = (
    data: HouseHoldGetQuoteReq
  ) => {
    // Check if PolicyDuration is ""
    if (data.PolicyDuration === "") {
      return { bol: true, field: "pd" };
    }
  
    if (data.ContactAddress === "") {
      return { bol: true, field: "ca" };
    }
  
    // Check if all selected fields are false
    for (const key in data) {
      // Exclude PolicyDuration from the check
      if (
        key !== "PolicyDuration" &&
        (data[key as keyof HouseHoldGetQuoteReq] as GetQuoteCategoryPair)
          .selected
      ) {
        return { bol: false, field: "" };
      }
    }
  
    // If PolicyDuration is not "" and all selected fields are false
    return { bol: true, field: "items" };
  };
  
  const filterSelectedTrue = (
    data: HouseHoldGetQuoteReq
  ): HouseHoldGetQuoteReq => {
    const filteredData: HouseHoldGetQuoteReq = { ...data };
  
    // Remove keys with selected: false
    for (const key in filteredData) {
      const value = filteredData[key as keyof HouseHoldGetQuoteReq];
      if (value && typeof value !== "string" && value.selected === false) {
        delete filteredData[key as keyof HouseHoldGetQuoteReq];
      }
    }
  
    return filteredData;
  };
  
  const isAnySelectedTrueWithNotEmptyValues = (data: HouseHoldGetQuoteReq) => {
    for (const key in data) {
      const innerKey = data[
        key as keyof HouseHoldGetQuoteReq
      ] as GetQuoteCategoryPair;
      if (key !== "PolicyDuration" && innerKey.selected) {
        if (innerKey.NumberOfUnits === "" || innerKey.Value === "") {
          return { bool: false, key }; // If any selected field has empty NumberOfUnits or Value, return false
        }
      }
    }
  
    return { bool: true, key: "" }; // If all selected fields have non-empty NumberOfUnits and Value, or no selected fields, return true
  };
  

const GetAQuote = ({
  setRentStep,
  closeAllDropDowns,
  rentDropDownValue,
}: {
  setRentStep: ActionCreatorWithPayload<number, "insurance/setRentStep">;
  closeAllDropDowns: () => void;
  rentDropDownValue: RentDropDown;
}) => {
  const dispatch = useDispatch();
  const { openError, openSuccess } = useContext(ToastContext);
  const { setLoading } = useContext(LoadingContext);
  const { personalDetails, excelFile, displayExcelData, HouseHoldGetQuoteRequest, HouseHoldGetQuoteRes } = useSelector((state: RootState) => state.insurance);

  const token = localStorage.getItem("token") as string;
  
  const [buildingData, setBuildingData] = useState<
    ExpectedJSONFromExcel | undefined
  >();
  const [notBuildingData, setNotBuildingData] = useState<
    ExpectedJSONFromExcel[] | []
  >([]);

  const [thirdBuildingData, setThirdBuildingData] = useState<
    ExpectedJSONFromExcel[] | []
  >([]);

  const [getAQuote, setgetAQuote] = useState<HouseHoldGetQuoteReq>({
   PolicyDuration: '',
   ContactAddress: '',
   Building: {
    NumberOfUnits: "",
    Value: "",
    selected: false,
  },
  AirConditioner: {
    NumberOfUnits: "",
    Value: "",
    selected: false,
  },
  Television:{
    NumberOfUnits: "",
    Value: "",
    selected: false,
  },
  Refrigerator: {
    NumberOfUnits: "",
    Value: "",
    selected: false,
  },
  Freezer: {
    NumberOfUnits: "",
    Value: "",
    selected: false,
  },
  HomeTheatre: {
    NumberOfUnits: "",
    Value: "",
    selected: false,
  },
  Table: {
    NumberOfUnits: "",
    Value: "",
    selected: false,
  },
  GasCooker: {
    NumberOfUnits: "",
    Value: "",
    selected: false,
  },
  Inverter: {
    NumberOfUnits: "",
    Value: "",
    selected: false,
  },
  Bed: {
    NumberOfUnits: "",
    Value: "",
    selected: false,
  },
  WaterDispenser: {
    NumberOfUnits: "",
    Value: "",
    selected: false,
  },
  WashingMachine: {
    NumberOfUnits: "",
    Value: "",
    selected: false,
  },
  LivingRoom: {
    NumberOfUnits: "",
    Value: "",
    selected: false,
  },
  KitchenCabinets: {
    NumberOfUnits: "",
    Value: "",
    selected: false,
  },
  Shoes: {
    NumberOfUnits: "",
    Value: "",
    selected: false,
  },
  Bags: {
    NumberOfUnits: "",
    Value: "",
    selected: false,
  },
  Clothing: {
    NumberOfUnits: "",
    Value: "",
    selected: false,
  },
  StandingFan: {
    NumberOfUnits: "",
    Value: "",
    selected: false,
  },
  Laptop: {
    NumberOfUnits: "",
    Value: "",
    selected: false,
  },
  Phone: {
    NumberOfUnits: "",
    Value: "",
    selected: false,
  },
  Jewelries: {
    NumberOfUnits: "",
    Value: "",
    selected: false,
  },
  WristWatch: {
    NumberOfUnits: "",
    Value: "",
    selected: false,
  },
  Camera: {
    NumberOfUnits: "",
    Value: "",
    selected: false,
  },
  Other: {
    NumberOfUnits: "",
    Value: "",
    selected: false,
  },
  Others: {
    NumberOfUnits: "",
    Value: "",
    selected: false,
  },
  });

  
console.log(getAQuote, 'here it is')

const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.name;
    const check = event.target.checked;
    console.log(event.target.value, event.target, 'ooooo====oooooo')
    const [nestedObjectKey, propertyKey] = name.split(".");

    setgetAQuote((prev) => ({
      ...prev,
      [nestedObjectKey]: {
        //@ts-ignore
        ...prev[nestedObjectKey],
        [propertyKey]: check,
      },
    }));
  };

  const handleTypedValChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    const [nestedObjectKey, propertyKey] = name.split(".");

    if (name === "PolicyDuration" || name === "ContactAddress") {
        setgetAQuote((prev) => ({
          ...prev,
          [name]:
            name === "PolicyDuration" ? value.replace(/[^0-9.]/g, "") : value,
        }));
        return;
      }
console.log(name, 'name===', value, '')
    setgetAQuote((prev) => ({
        ...prev,
        [nestedObjectKey]: {
          //@ts-ignore
          ...prev[nestedObjectKey],
          [propertyKey]:
            propertyKey === "Value" ? value.replace(/[^0-9.]/g, "") : value,
        },
      }));
  };

  const handleCreateQuoteSubmit = async (req: HouseHoldGetQuoteReq) => {
    try{
    setLoading(true);
    const quoteInfoArr: QuoteInfoDetail[] = [];
    console.log(req, 'here is the request =====')
    for (let key in req) {
      let value = req[key as keyof HouseHoldGetQuoteReq];
      console.log(key, "------=-0000");
      
      if (value !== undefined && typeof value !== "string") {
        quoteInfoArr.push({
          itemName: key,
          itemContactAddress: req.ContactAddress ? req.ContactAddress : "",
          sumInsuredValue:
            parseFloat(value.Value) * parseFloat(value.NumberOfUnits),
          // assetType: key === "Building" ? AssetTypeList.Non : AssetTypeList.All
          assetType: assetTypeLists
            .find((ass) => ass.item === key)!
            .type.toString()
            ,
          category: "Content"
          // categoryLists
          //   .find((ass) => ass.item === key)!
          //   .type.toString()
            ,
          coverType: "Fire and Burglary"
        });
      }
    }
    console.log(quoteInfoArr, "hello =========--===")
    const { data, error } = await householdCreateQuoteApi(
      {
        email: personalDetails.Email,
        phoneNumber: personalDetails.PhoneNumber!,
        firstName: personalDetails.FirstName,
        middleName:personalDetails.MiddleName,
        lastName: personalDetails.LastName,
        duration: Number(req.PolicyDuration),
        title: personalDetails.Title + 1,
        quoteInfoDetails: quoteInfoArr,
      },
      token
    );

    if (data) {
      dispatch(setHouseHoldGetQuoteRes(data.data));
      setLoading(false);
      openSuccess(data.message ? data.message : "Quote created successfully");
      return;
    }

    if (error) {
      setLoading(false);
      openError(
        returnMsgFromAxiosError(error, "An error occurred creating quote")
      );
      return;
    }

    setLoading(false);
    openError("An error occurred creating quote");
    
  } catch (error) {
    // Handle unexpected errors
    console.error("An unexpected error occurred:", error);
    setLoading(false);
    openError("An unexpected error occurred");
}    
  };



const saveContinue = (
    polDuration: string,
    contact: string | undefined,
    dataJSON: ExpectedJSONFromExcel[],
    manualReq: HouseHoldGetQuoteReq,
    excelFileI: File | undefined
  ) => {
    if (excelFileI !== undefined) {
        const reqObj = transformExpectedJSONToHouseHoldReq(
          polDuration,
          contact,
          dataJSON
        );
       console.log(reqObj, 'reqObj==---===')
        const { bol, field } = isPolicyDurationEmptyOrAllSelectedFalse(reqObj);
        if (bol === true && field === "pd") {
          openError("Please fill policy duration");
          return;
        }
        if (bol === true && field === "ca") {
          openError("Please fill contact address");
          return;
        }
        if (bol === true && field === "items") {
          openError("Please fill in at least one insurance item");
          return;
        }
        console.log(reqObj, 'reqObj==---=== ++')
        const { bool, key } = isAnySelectedTrueWithNotEmptyValues(reqObj);
        if (bool === false) {
          openError(`Please fill units and value for ${key}`);
          return;
        }
        console.log(reqObj, 'reqObj==---=== ???')
        dispatch(setHouseHoldGetQuoteRequest(reqObj));
        handleCreateQuoteSubmit(reqObj);
      } else {

      const manualRequest = filterSelectedTrue(manualReq);
      console.log(manualReq, "first request", manualRequest, 'manual request ==== ==--=')
      const { bol, field } =
        isPolicyDurationEmptyOrAllSelectedFalse(manualRequest);
      if (bol === true && field === "pd") {
        openError("Please fill policy duration");
        return;
      }
      if (bol === true && field === "ca") {
        openError("Please fill contact address");
        return;
      }
      if (bol === true && field === "items") {
        openError("Please fill in at least one insurance item");
        return;
      }
      const { bool, key } = isAnySelectedTrueWithNotEmptyValues(manualRequest);
      if (bool === false) {
        openError(`Please fill units and value for ${key}`);
        return;
      }
      dispatch(setHouseHoldGetQuoteRequest(manualRequest));
      handleCreateQuoteSubmit(manualRequest);
    }
  };


  const goBack = () => {
    dispatch(setRentStep(1));
  };

  const goNext = () => {
    if (HouseHoldGetQuoteRes !== undefined) {
      dispatch(setRentStep(3));
    } else {
      openError("Please fill the form");
    }
  };
  const handleDocsChange = (name: string, value: File) => {
    if(!Array.isArray(value))
    dispatch(setExcelFile(value));
    // dispatch(setDisplayExcelData([]));
  };

  const removeDoc = (name: string) => {
    setBuildingData(undefined);
    dispatch(setExcelFile(undefined));
  };

  const checkForOtherItems = (checkedJson: ExpectedJSONFromExcel[]) => {
    const othersObj = checkedJson.filter(
      (item) => !itemsToBeDisplayed.includes(item.items.toLowerCase())
    );
    const otherSum = othersObj.reduce(
      (acc: number, curr: ExpectedJSONFromExcel) => {
        return Number(acc) + Number(curr.value);
      },
      0
    );

    const otherUnit = othersObj.reduce(
      (acc: number, curr: ExpectedJSONFromExcel) => {
        return Number(acc) + Number(curr.units);
      },
      0
    );

    const hasOthers = otherSum > 0;

    return { otherSum, otherUnit, hasOthers };
  };
  
  const handleExcelJsonDisplay = (data: unknown) => {
    if (data && Array.isArray(data) && data.length > 0) {
      const newData = convertKeysToLowerCase(data);
      const oneObj = newData[0];
      const oneObjKeys = Object.keys(oneObj).map((key) => key.toLowerCase());

      if (
        (oneObjKeys && oneObjKeys.length && oneObjKeys.length !== 3) ||
        !oneObjKeys.every((key) => acceptedKeys.includes(key))
      ) {
        openError("Please use 'items', 'units' and 'value' as column headings");
        dispatch(setExcelFile(undefined));
        return;
      }

      if (
        newData &&
        newData.some(
          (data) => data.items.toLowerCase() === ("others" || "other")
        )
      ) {
        openError("Please specify items under other(s)");
        dispatch(setExcelFile(undefined));
        return;
      }

      dispatch(setDisplayExcelData(newData));
      if (newData && newData.length > 0) {
        setBuildingData(
          newData.find((data) => data.items.toLowerCase() === "building")
        );

        setNotBuildingData(
          newData.filter(
            (data) =>
              data.items.toLowerCase() !== "building" &&
              itemsToBeDisplayed.includes(data.items.toLocaleLowerCase())
          )
        );

        setThirdBuildingData(
          newData.filter(
            (data) =>
              data.items.toLowerCase() !== "building" &&
              itemsToBeDisplayed2.includes(data.items.toLocaleLowerCase())
          )
        );


        const { hasOthers, otherSum, otherUnit } = checkForOtherItems(newData);
        if (hasOthers === true) {
          setNotBuildingData((prev) => [
            ...prev,
            {
              items: "Others",
              value: otherSum.toString(),
              units: otherUnit.toString(),
            },
          ]);
        }
      }
    }
  };
  
  useEffect(() => {
    if (HouseHoldGetQuoteRequest && HouseHoldGetQuoteRequest.PolicyDuration) {
      setgetAQuote(HouseHoldGetQuoteRequest);
    }
  }, []);

  useEffect(() => {
    if (excelFile !== undefined) {
      const reader = new FileReader();
      reader.onload = (e) => {
        const workBook = read(e.target?.result);
        const sheet = workBook.SheetNames;
        if (sheet && sheet.length > 0) {
          const data = utils.sheet_to_json(workBook.Sheets[sheet[0]]);
          handleExcelJsonDisplay(data);
        }
      };

      reader.readAsArrayBuffer(excelFile);
    }
  }, [excelFile]);

  return (
    <>
    <div>
    <div className={styles.form}>
        <p>To get a quote, you can choose an option below to upload an excel file of the items 
            you wish to insure, or manually input them. </p>
    </div>
    <div className={styles.policydiv}>
     <div className={styles.greenblockdiv}>
        <img src={greenBlock} alt="" />
        <h6 className={styles.headertext}>Policy Duration</h6>
     </div>
     <p>Kindly input your preferred policy duration in months.</p>
     <div className={styles.inputcolumns}>
            <span>
              Policy Duration (Months)<span className={styles.red}>*</span>
            </span>
            <input
              name="PolicyDuration"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.PolicyDuration}
            />
          </div> 
         
    </div>
    <div className={styles["inputcolumn-65"]}>
            <span>
              Items' Contact Address<span className={styles.red}>*</span>
            </span>
            <input
              name="ContactAddress"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.ContactAddress}
            />
          </div>  

    <div className={styles.policydiv}>
     <div className={styles.greenblockdiv}>
        <img src={greenBlock} alt="" />
        <h6 className={styles.smallText}> Insurance Items</h6>
     </div>
     <p>To get a quote, you can choose an option below to upload an excel file of the items you wish to insure, or manuallly input them.</p>
    </div>

      <div className={styles.docTextDiv}>
        <div className={styles.policydiv}>
        <h6 className={styles.sndHeaderText}>Upload and excel file</h6>
        <span>Only xlsx files are allowed.</span>
        <br/>
        <span>File must have 3 columns; the items, the units and the value in Nigerian Naira.</span>
        <br/>
        <span>Large files might take longer to be processed.</span>
        <p>Merged multiple documents are not allowed.</p>
        </div>
        <div>
            <UploadSingleButton 
            buttonTxt={"Upload Items"} 
            handleDocsChange={handleDocsChange} 
            accept={".xls,.xlsx,.xlsm,.xlsb,.xltm,.xltx,.csv"} 
            name={""} 
            removeDoc={removeDoc} 
            docValue={excelFile}/>
        </div>
        </div>

        {/* {buildingData && excelFile ? (
            <>
              <span className={styles["category-head-hm"]}>
                Category A: Building (0.2% premium)
              </span>
              <div className={styles["category-hm"]}>
                <div className={styles["category-left"]}>
                  <div className={styles["category-left-inner"]}>
                    <FormGroup>
                      <FormControlLabel
                        control={
                          <Checkbox
                            name="Building"
                            className={styles["category-hm-check-cont"]}
                            defaultChecked={true}
                          />
                        }
                        color="success"
                        label="Building"
                        defaultChecked={true}
                      />
                    </FormGroup>
                  </div>
                </div>
                <div className={styles["category-right"]}>
                  <div className={styles["category-right-inner"]}>
                    <div className={styles.columngrid}>
                      <div className={styles.inputcolumn}>
                        <span>Number of units</span>
                        <input
                          name="Building.NumberOfUnits"
                          disabled
                          className={styles.inputfield}
                          autoComplete="off"
                          value={buildingData.units}
                          // defaultValue={buildingData.units}
                        />
                      </div>
                      <div className={styles["inputcolumn-65"]}>
                        <span>Value Of Each Unit (NGN)</span>
                        <input
                          name="Building.Value"
                          disabled
                          className={styles.inputfield}
                          autoComplete="off"
                          value={
                            buildingData.value
                              ? formatAmount(parseFloat(buildingData.value))
                              : ""
                          }
                          // defaultValue={
                          //   buildingData.value
                          //     ? formatAmount(parseFloat(buildingData.value))
                          //     : ""
                          // }
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </>
          ) : null} */}

    {notBuildingData && notBuildingData.length > 0 && excelFile ? (
            <>
              <span className={styles["category-head-hm"]}>
              Category A: Building content(0.5% premium)
              </span>

              <div className={styles["category-hm"]}>
                <div className={styles["category-left"]}>
                  {notBuildingData.map((data) => (
                    <div
                      className={styles["category-left-inner"]}
                      key={data.items.charCodeAt(9)}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={data.items}
                              value={true}
                              className={styles["category-hm-check-cont"]}
                              defaultChecked={true}
                            />
                          }
                          color="success"
                          defaultChecked={true}
                          label={data.items}
                        />
                      </FormGroup>
                    </div>
                  ))}
                </div>
                <div className={styles["category-right"]}>
                  {notBuildingData.map((data) => (
                    <div
                      className={styles["category-right-inner"]}
                      key={data.items.charCodeAt(9)}
                    >
                      <div className={styles.columngrid}>
                        <div className={styles.inputcolumn}>
                          <span>Number of units</span>
                          <input
                            name="NumberOfUnits"
                            disabled
                            className={styles.inputfield}
                            autoComplete="off"
                            value={data.units}
                            // defaultValue={data.units}
                          />
                        </div>
                        <div className={styles["inputcolumn-65"]}>
                          <span>Value Of Each Unit (NGN)</span>
                          <input
                            name="Value"
                            disabled
                            className={styles.inputfield}
                            autoComplete="off"
                            value={
                              data.value
                                ? formatAmount(parseFloat(data.value))
                                : ""
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : null}

{thirdBuildingData && thirdBuildingData.length > 0 && excelFile ? (
            <>
              <span className={styles["category-head-hm"]}>
                Category B: All Risk (3% premium)
              </span>

              <div className={styles["category-hm"]}>
                <div className={styles["category-left"]}>
                  {thirdBuildingData.map((data) => (
                    <div
                      className={styles["category-left-inner"]}
                      key={data.items.charCodeAt(9)}
                    >
                      <FormGroup>
                        <FormControlLabel
                          control={
                            <Checkbox
                              name={data.items}
                              value={true}
                              className={styles["category-hm-check-cont"]}
                              defaultChecked={true}
                            />
                          }
                          color="success"
                          defaultChecked={true}
                          label={data.items}
                        />
                      </FormGroup>
                    </div>
                  ))}
                </div>
                <div className={styles["category-right"]}>
                  {thirdBuildingData.map((data) => (
                    <div
                      className={styles["category-right-inner"]}
                      key={data.items.charCodeAt(9)}
                    >
                      <div className={styles.columngrid}>
                        <div className={styles.inputcolumn}>
                          <span>Number of units</span>
                          <input
                            name="NumberOfUnits"
                            disabled
                            className={styles.inputfield}
                            autoComplete="off"
                            value={data.units}
                            // defaultValue={data.units}
                          />
                        </div>
                        <div className={styles["inputcolumn-65"]}>
                          <span>Value Of Each Unit (NGN)</span>
                          <input
                            name="Value"
                            disabled
                            className={styles.inputfield}
                            autoComplete="off"
                            value={
                              data.value
                                ? formatAmount(parseFloat(data.value))
                                : ""
                            }
                          />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </div>
            </>
          ) : null}

    {/* CATEGORY A */}
    {displayExcelData.length === 0 || excelFile === undefined ? (
   <>
    {/* <div>
    <h6 className={styles.mainHeaderText}>Manually input Insurance items</h6>
    <h6>Category A: Building(0.2% premium)</h6>
      <div className={styles.cardContainer} >
        <div className={styles.checkboxCard}>
          <div className={styles.inputdivs}>
          <FormGroup>
                <FormControlLabel
                    control={
                          <Checkbox
                            name="Building.selected"
                            className={styles["category-hm-check-cont"]}
                            onChange={handleChange}
                          />
                        }
                        color="success"
                        label="Building"
                      />
                    </FormGroup>
              <hr/>
              </div>
        </div>
        <div className={styles.columngrids}> 
        <div className={styles.rowgrids}>
          <div className={styles.inputcolumns}>
            <span>
              Number of units
            </span>
            <input
              name="Building.NumberOfUnits"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Building?.NumberOfUnits}
            //   defaultValue={getAQuote.Building?.NumberOfUnits}
            />
          </div>

          <div className={styles["inputcolumns-65"]}>
            <span>
              Value of each unit (NGN)
            </span>
            <input
              name="Building.Value"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Building?.Value}
            //   defaultValue={getAQuote.Building?.Value}
            />
          </div>
          </div>
          </div>
      </div>
    </div> */}
    {/* )} */}

    {/* CATEGORY B */}
    
    <div className={styles.sndCategory}>
     <h6>Category A: Building content(0.5% premium)</h6>
      <div className={styles.cardContainer} >
        <div className={styles.checkboxCard}>
            <div className={styles.inputdiv}>
            <FormGroup>
                <FormControlLabel
                    control={
                          <Checkbox
                            name="AirConditioner.selected"
                            className={styles["category-hm-check-cont"]}
                            onChange={handleChange}
                          />
                        }
                        color="success"
                        label="Air Conditioner"
                      />
                    </FormGroup>
              <hr/>
              </div>
              <div className={styles.inputdiv}>
              <FormGroup>
                <FormControlLabel
                    control={
                          <Checkbox
                            name="Television.selected"
                            className={styles["category-hm-check-cont"]}
                            onChange={handleChange}
                          />
                        }
                        color="success"
                        label="Television"
                      />
                    </FormGroup>
               <hr/>
               </div>
               <div className={styles.inputdiv}>
               <FormGroup>
                <FormControlLabel
                    control={
                          <Checkbox
                            name="Refrigerator.selected"
                            className={styles["category-hm-check-cont"]}
                            onChange={handleChange}
                          />
                        }
                        color="success"
                        label="Refrigerator"
                      />
                    </FormGroup>
               <hr/>
               </div>
               <div className={styles.inputdiv}>
               <FormGroup>
                <FormControlLabel
                    control={
                          <Checkbox
                            name="Freezer.selected"
                            className={styles["category-hm-check-cont"]}
                            onChange={handleChange}
                          />
                        }
                        color="success"
                        label="Freezer"
                      />
                    </FormGroup>
               <hr/>
               </div>
               <div className={styles.inputdiv}>
               <FormGroup>
                <FormControlLabel
                    control={
                          <Checkbox
                            name="HomeTheatre.selected"
                            className={styles["category-hm-check-cont"]}
                            onChange={handleChange}
                          />
                        }
                        color="success"
                        label="Home Theatre"
                      />
                    </FormGroup>
               <hr/>
               </div>
               <div className={styles.inputdiv}>
               <FormGroup>
                <FormControlLabel
                    control={
                          <Checkbox
                            name="Table.selected"
                            className={styles["category-hm-check-cont"]}
                            onChange={handleChange}
                          />
                        }
                        color="success"
                        label="Table"
                      />
                    </FormGroup>
               <hr/>
               </div>
               <div className={styles.inputdiv}>
               <FormGroup>
                <FormControlLabel
                    control={
                          <Checkbox
                            name="GasCooker.selected"
                            className={styles["category-hm-check-cont"]}
                            onChange={handleChange}
                          />
                        }
                        color="success"
                        label="GasCooker"
                      />
                    </FormGroup>
               <hr/>
               </div>
               <div className={styles.inputdiv}>
               <FormGroup>
                <FormControlLabel
                    control={
                          <Checkbox
                            name="Inverter.selected"
                            className={styles["category-hm-check-cont"]}
                            onChange={handleChange}
                          />
                        }
                        color="success"
                        label="Inverter"
                      />
                    </FormGroup>
               <hr/>
               </div>
             
               <div className={styles.inputdiv}>
               <FormGroup>
                <FormControlLabel
                    control={
                          <Checkbox
                            name="Bed.selected"
                            className={styles["category-hm-check-cont"]}
                            onChange={handleChange}
                          />
                        }
                        color="success"
                        label="Bed & Beddings"
                      />
                    </FormGroup>
               <hr/>
               </div>
               <div className={styles.inputdiv}>
               <FormGroup>
                <FormControlLabel
                    control={
                          <Checkbox
                            name="WaterDispenser.selected"
                            className={styles["category-hm-check-cont"]}
                            onChange={handleChange}
                          />
                        }
                        color="success"
                        label="Water Dispenser"
                      />
                    </FormGroup>
               <hr/>
               </div>
               <div className={styles.inputdiv}>
               <FormGroup>
                <FormControlLabel
                    control={
                          <Checkbox
                            name="WashingMachine.selected"
                            className={styles["category-hm-check-cont"]}
                            onChange={handleChange}
                          />
                        }
                        color="success"
                        label="Washing Machine"
                      />
                    </FormGroup>
               <hr/>
               </div>
               <div className={styles.inputdiv}>
               <FormGroup>
                <FormControlLabel
                    control={
                          <Checkbox
                            name="LivingRoom.selected"
                            className={styles["category-hm-check-cont"]}
                            onChange={handleChange}
                          />
                        }
                        color="success"
                        label="Living Room Sette"
                      />
                    </FormGroup>
               <hr/>
               </div>
               <div className={styles.inputdiv}>
               <FormGroup>
                <FormControlLabel
                    control={
                          <Checkbox
                            name="KitchenCabinets.selected"
                            className={styles["category-hm-check-cont"]}
                            onChange={handleChange}
                          />
                        }
                        color="success"
                        label="Kitchen Cabinets"
                      />
                    </FormGroup>
               <hr/>
               </div>
               <div className={styles.inputdiv}>
               <FormGroup>
                <FormControlLabel
                    control={
                          <Checkbox
                            name="Other.selected"
                            className={styles["category-hm-check-cont"]}
                            onChange={handleChange}
                          />
                        }
                        color="success"
                        label="Other (Non-movable items)"
                      />
                    </FormGroup>
                  {/* <Input
                labelName='Other (Non-movable items)'
                type="checkbox"
                classes="checkbox-inlines"
                name="other"
                value="true"
                onChange={handleChange}
              /> */}
              <hr/>
               </div>
        </div>
        <div className={styles.columngrids}>
        <div className={styles.rowgrid}>
          <div className={styles.inputcolumns}>
            <span>
              Number of units
            </span>
            <input
              name="AirConditioner.NumberOfUnits"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.AirConditioner?.NumberOfUnits}
            />
          </div>

          <div className={styles["inputcolumns-65"]}>
            <span>
              Value of each unit(NGN)
            </span>
            <input
              name="AirConditioner.Value"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.AirConditioner?.Value}
            />
          </div>
          </div>
          <div className={styles.rowgrid}>
          <div className={styles.inputcolumns}>
            <span>
              Number of units
            </span>
            <input
              name="Television.NumberOfUnits"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Television?.NumberOfUnits}
            />
          </div>

          <div className={styles["inputcolumns-65"]}>
            <span>
              Value of each unit(NGN)
            </span>
            <input
              name="Television.Value"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Television?.Value}
            />
          </div>
          </div>
          <div className={styles.rowgrid}>
          <div className={styles.inputcolumns}>
            <span>
              Number of units
            </span>
            <input
              name="Refrigerator.NumberOfUnits"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Refrigerator?.NumberOfUnits}
            />
          </div>

          <div className={styles["inputcolumns-65"]}>
            <span>
              Value of each unit(NGN)
            </span>
            <input
              name="Refrigerator.Value"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Refrigerator?.Value}
            />
          </div>
          </div>
          <div className={styles.rowgrid}>
          <div className={styles.inputcolumns}>
            <span>
              Number of units<span className={styles.red}>*</span>
            </span>
            <input
              name="Freezer.NumberOfUnits"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Freezer?.NumberOfUnits}
            />
          </div>

          <div className={styles["inputcolumns-65"]}>
            <span>
              Value of each unit(NGN)
            </span>
            <input
              name="Freezer.Value"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Freezer?.Value}
            />
          </div>
          </div>
          <div className={styles.rowgrid}>
          <div className={styles.inputcolumns}>
            <span>
              Number of units
            </span>
            <input
              name="HomeTheatre.NumberOfUnits"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.HomeTheatre?.NumberOfUnits}
            />
          </div>

          <div className={styles["inputcolumns-65"]}>
            <span>
              Value of each unit(NGN)
            </span>
            <input
              name="HomeTheatre.Value"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.HomeTheatre?.Value}
            />
          </div>
          </div>
          <div className={styles.rowgrid}>
          <div className={styles.inputcolumns}>
            <span>
              Number of units
            </span>
            <input
              name="Table.NumberOfUnits"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Table?.NumberOfUnits}
            />
          </div>

          <div className={styles["inputcolumns-65"]}>
            <span>
             Value of each unit(NGN)
            </span>
            <input
              name="Table.Value"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Table?.Value}
            />
          </div>
          </div>
          <div className={styles.rowgrid}>
          <div className={styles.inputcolumns}>
            <span>
              Number of units
            </span>
            <input
              name="GasCooker.NumberOfUnits"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.GasCooker?.NumberOfUnits}
            />
          </div>

          <div className={styles["inputcolumns-65"]}>
            <span>
              Value of each unit(NGN)
            </span>
            <input
              name="GasCooker.Value"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.GasCooker?.Value}
            />
          </div>
          </div>
          <div className={styles.rowgrid}>
          <div className={styles.inputcolumns}>
            <span>
              Number of units
            </span>
            <input
              name="Inverter.NumberOfUnits"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Inverter?.NumberOfUnits}
            />
          </div>

          <div className={styles["inputcolumns-65"]}>
            <span>
              Value of each unit(NGN)
            </span>
            <input
              name="Inverter.Value"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Inverter?.Value}
            />
          </div>
          </div>
          <div className={styles.rowgrid}>
          <div className={styles.inputcolumns}>
            <span>
              Number of units
            </span>
            <input
              name="Bed.NumberOfUnits"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Bed?.NumberOfUnits}
            />
          </div>

          <div className={styles["inputcolumns-65"]}>
            <span>
              Value of each unit(NGN)
            </span>
            <input
              name="Bed.Value"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Bed?.Value}
            />
          </div>
          </div>
          <div className={styles.rowgrid}>
          <div className={styles.inputcolumns}>
            <span>
              Number of units
            </span>
            <input
              name="WaterDispenser.NumberOfUnits"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.WaterDispenser?.NumberOfUnits}
            />
          </div>

          <div className={styles["inputcolumns-65"]}>
            <span>
              Value of each unit(NGN)
            </span>
            <input
              name="WaterDispenser.Value"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.WaterDispenser?.Value}
            />
          </div>
          </div>
          <div className={styles.rowgrid}>
          <div className={styles.inputcolumns}>
            <span>
              Number of units
            </span>
            <input
              name="WashingMachine.NumberOfUnits"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.WashingMachine?.NumberOfUnits}
            />
          </div>

          <div className={styles["inputcolumns-65"]}>
            <span>
              Value of each unit(NGN)
            </span>
            <input
              name="WashingMachine.Value"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.WashingMachine?.Value}
            />
          </div>
          </div>
          <div className={styles.rowgrid}>
          <div className={styles.inputcolumns}>
            <span>
              Number of units
            </span>
            <input
              name="LivingRoom.NumberOfUnits"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.LivingRoom?.NumberOfUnits}
            />
          </div>

          <div className={styles["inputcolumns-65"]}>
            <span>
              Value of each unit(NGN)
            </span>
            <input
              name="LivingRoom.Value"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.LivingRoom?.Value}
            />
          </div>
          </div>
          <div className={styles.rowgrid}>
          <div className={styles.inputcolumns}>
            <span>
              Number of units
            </span>
            <input
              name="KitchenCabinets.NumberOfUnits"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.KitchenCabinets?.NumberOfUnits}
            />
          </div>

          <div className={styles["inputcolumns-65"]}>
            <span>
            Value of each unit(NGN)
            </span>
            <input
              name="KitchenCabinets.Value"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.KitchenCabinets?.Value}
            />
          </div>
          </div>
          <div className={styles.rowgrid}>
          <div className={styles.inputcolumns}>
            <span>
              Number of units
            </span>
            <input
              name="Other.NumberOfUnits"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Other?.NumberOfUnits}
            />
          </div>

          <div className={styles["inputcolumns-65"]}>
            <span>
              Value of each unit(NGN)
              </span>
            <input
              name="Other.Value"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Other?.Value}
            />
          </div>
          </div>
        </div>
      </div>
    </div>
 {/* CATEGORY C */}
    <div className={styles.sndCategory}>
     <h6>Category B: All Risks (3% premium)</h6>
      <div className={styles.cardContainer} >
        <div className={styles.checkboxCard}>
            <div className={styles.inputdiv}>
            <FormGroup>
                <FormControlLabel
                    control={
                          <Checkbox
                            name="Shoes.selected"
                            className={styles["category-hm-check-cont"]}
                            onChange={handleChange}
                          />
                        }
                        color="success"
                        label="Shoes"
                      />
                    </FormGroup>
              <hr/>
              </div>
              <div className={styles.inputdiv}>
              <FormGroup>
                <FormControlLabel
                    control={
                          <Checkbox
                            name="Bags.selected"
                            className={styles["category-hm-check-cont"]}
                            onChange={handleChange}
                          />
                        }
                        color="success"
                        label="Bags"
                      />
                    </FormGroup>
               <hr/>
               </div>
               <div className={styles.inputdiv}>
               <FormGroup>
                <FormControlLabel
                    control={
                          <Checkbox
                            name="Clothing.selected"
                            className={styles["category-hm-check-cont"]}
                            onChange={handleChange}
                          />
                        }
                        color="success"
                        label="Clothing"
                      />
                    </FormGroup>
               <hr/>
               </div>
               <div className={styles.inputdiv}>
               <FormGroup>
                <FormControlLabel
                    control={
                          <Checkbox
                            name="StandingFan.selected"
                            className={styles["category-hm-check-cont"]}
                            onChange={handleChange}
                          />
                        }
                        color="success"
                        label="Standing Fan"
                      />
                    </FormGroup>
               <hr/>
               </div>
               <div className={styles.inputdiv}>
               <FormGroup>
                <FormControlLabel
                    control={
                          <Checkbox
                            name="WristWatch.selected"
                            className={styles["category-hm-check-cont"]}
                            onChange={handleChange}
                          />
                        }
                        color="success"
                        label="Wrist Watches"
                      />
                    </FormGroup>
               <hr/>
               </div>
               <div className={styles.inputdiv}>
               <FormGroup>
                <FormControlLabel
                    control={
                          <Checkbox
                            name="Camera.selected"
                            className={styles["category-hm-check-cont"]}
                            onChange={handleChange}
                          />
                        }
                        color="success"
                        label="Camera"
                      />
                    </FormGroup>
               <hr/>
               </div>
               <div className={styles.inputdiv}>
               <FormGroup>
                <FormControlLabel
                    control={
                          <Checkbox
                            name="Laptop.selected"
                            className={styles["category-hm-check-cont"]}
                            onChange={handleChange}
                          />
                        }
                        color="success"
                        label="Laptop"
                      />
                    </FormGroup>
               <hr/>
               </div>
               <div className={styles.inputdiv}>
               <FormGroup>
                <FormControlLabel
                    control={
                          <Checkbox
                            name="Phone.selected"
                            className={styles["category-hm-check-cont"]}
                            onChange={handleChange}
                          />
                        }
                        color="success"
                        label="Phone"
                      />
                    </FormGroup>
               <hr/>
               </div>
               <div className={styles.inputdiv}>
               <FormGroup>
                <FormControlLabel
                    control={
                          <Checkbox
                            name="Jewelries.selected"
                            className={styles["category-hm-check-cont"]}
                            onChange={handleChange}
                          />
                        }
                        color="success"
                        label="Jewelries"
                      />
                    </FormGroup>
               <hr/>
               </div>
               <div className={styles.inputdiv}>
               <FormGroup>
                <FormControlLabel
                    control={
                          <Checkbox
                            name="Others.selected"
                            className={styles["category-hm-check-cont"]}
                            onChange={handleChange}
                          />
                        }
                        color="success"
                        label="Others (Non-movable items)"
                      />
                    </FormGroup>
               <hr/>
               </div>
        </div>
        <div className={styles.columngrids}>
        <div className={styles.rowgrid}>
          <div className={styles.inputcolumns}>
            <span>
              Number of units
            </span>
            <input
              name="Shoes.NumberOfUnits"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Shoes?.NumberOfUnits}
            //   defaultValue={getAQuote.Shoes?.NumberOfUnits}
            />
          </div>

          <div className={styles["inputcolumns-65"]}>
            <span>
              Value of each unit(NGN)
            </span>
            <input
              name="Shoes.Value"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Shoes?.Value}
            //   defaultValue={getAQuote.Shoes?.Value}
            />
          </div>
          </div>
          <div className={styles.rowgrid}>
          <div className={styles.inputcolumns}>
            <span>
              Number of units
            </span>
            <input
              name="Bags.NumberOfUnits"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Bags?.NumberOfUnits}
            //   defaultValue={getAQuote.Bags?.NumberOfUnits}
            />
          </div>

          <div className={styles["inputcolumns-65"]}>
            <span>
              Value of each unit(NGN)
            </span>
            <input
              name="Bags.Value"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Bags?.Value}
            //   defaultValue={getAQuote.Bags?.Value}
            />
          </div>
          </div>
          <div className={styles.rowgrid}>
          <div className={styles.inputcolumns}>
            <span>
              Number of units
            </span>
            <input
              name="Clothing.NumberOfUnits"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Clothing?.NumberOfUnits}
            //   defaultValue={getAQuote.Clothing?.NumberOfUnits}
            />
          </div>

          <div className={styles["inputcolumns-65"]}>
            <span>
              Value of each unit(NGN)<span className={styles.red}>*</span>
            </span>
            <input
              name="Clothing.Value"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Clothing?.Value}
            //   defaultValue={getAQuote.Clothing?.Value}
            />
          </div>
          </div>
          <div className={styles.rowgrid}>
          <div className={styles.inputcolumns}>
            <span>
              Number of units<span className={styles.red}>*</span>
            </span>
            <input
              name="StandingFan.NumberOfUnits"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.StandingFan?.NumberOfUnits}
            //   defaultValue={getAQuote.StandingFan?.NumberOfUnits}
            />
          </div>

          <div className={styles["inputcolumns-65"]}>
            <span>
              Value of each unit(NGN)
            </span>
            <input
              name="StandingFan.Value"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.StandingFan?.Value}
            //   defaultValue={getAQuote.StandingFan?.Value}
            />
          </div>
          </div>
          <div className={styles.rowgrid}>
          <div className={styles.inputcolumns}>
            <span>
              Number of units
            </span>
            <input
              name="WristWatch.NumberOfUnits"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.WristWatch?.NumberOfUnits}
            //   defaultValue={getAQuote.WristWatch?.NumberOfUnits}
            />
          </div>

          <div className={styles["inputcolumns-65"]}>
            <span>
              Value of each unit(NGN)
            </span>
            <input
              name="WristWatch.Value"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.WristWatch?.Value}
            //   defaultValue={getAQuote.WristWatch?.Value}
            />
          </div>
          </div>
          <div className={styles.rowgrid}>
          <div className={styles.inputcolumns}>
            <span>
              Number of units
            </span>
            <input
              name="Camera.NumberOfUnits"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Camera?.NumberOfUnits}
            //   defaultValue={getAQuote.Camera?.NumberOfUnits}
            />
          </div>

          <div className={styles["inputcolumns-65"]}>
            <span>
             Value of each unit(NGN)
            </span>
            <input
              name="Camera.Value"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Camera?.Value}
            //   defaultValue={getAQuote.Camera?.Value}
            />
          </div>
          </div>
          <div className={styles.rowgrid}>
          <div className={styles.inputcolumns}>
            <span>
              Number of units
            </span>
            <input
              name="Laptop.NumberOfUnits"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Laptop?.NumberOfUnits}
            //   defaultValue={getAQuote.Laptop?.NumberOfUnits}
            />
          </div>

          <div className={styles["inputcolumns-65"]}>
            <span>
              Value of each unit(NGN)
            </span>
            <input
              name="Laptop.Value"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Laptop?.Value}
            //   defaultValue={getAQuote.Laptop?.Value}
            />
          </div>
          </div>
          <div className={styles.rowgrid}>
          <div className={styles.inputcolumns}>
            <span>
              Number of units
            </span>
            <input
              name="Phone.NumberOfUnits"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Phone?.NumberOfUnits}
            //   defaultValue={getAQuote.Phone?.NumberOfUnits}
            />
          </div>

          <div className={styles["inputcolumns-65"]}>
            <span>
              Value of each unit(NGN)
            </span>
            <input
              name="Phone.Value"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Phone?.Value}
            //   defaultValue={getAQuote.Phone?.Value}
            />
          </div>
          </div>
          <div className={styles.rowgrid}>
          <div className={styles.inputcolumns}>
            <span>
              Number of units
            </span>
            <input
              name="Jewelries.NumberOfUnits"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Jewelries?.NumberOfUnits}
            //   defaultValue={getAQuote.Jewelries?.NumberOfUnits}
            />
          </div>

          <div className={styles["inputcolumns-65"]}>
            <span>
              Value of each unit(NGN)
            </span>
            <input
              name="Jewelries.Value"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Jewelries?.Value}
            //   defaultValue={getAQuote.Jewelries?.Value}
            />
          </div>
          </div>
          <div className={styles.rowgrid}>
          <div className={styles.inputcolumns}>
            <span>
              Number of units
            </span>
            <input
              name="Others.NumberOfUnits"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Others?.NumberOfUnits}
            //   defaultValue={getAQuote.Others?.NumberOfUnits}
            />
          </div>

          <div className={styles["inputcolumns-65"]}>
            <span>
              Value of each unit(NGN)
            </span>
            <input
              name="Others.Value"
              onChange={handleTypedValChange}
              className={styles.inputfield}
              autoComplete="off"
              value={getAQuote.Others?.Value}
            //   defaultValue={getAQuote.Others?.Value}
            />
          </div>
          </div>
        </div>
      </div>
    </div>
    </>
    ) : null}
    </div>
    

  {HouseHoldGetQuoteRes === undefined ? (
    // <div className={styles["quote-box"]}>
          <div
            role="button"
            onClick={() =>
              saveContinue(
                getAQuote.PolicyDuration,
                getAQuote.ContactAddress,
                displayExcelData,
                getAQuote,
                excelFile
              )
            }
            className={styles["get-a-quote-button"]}
          >
            <span>Get a Quote</span>
          </div>
        //   </div>
        ) : (
          <div className={styles.inputdivs}>
            <div className={styles["get-quote-cont"]}>
              <span className={styles["total-insured-value-p"]}>
                Total Value of Insured Items
              </span>
              <br/>
              <span className={styles["get-quote-header"]}>
                {HouseHoldGetQuoteRes.totalSumInsured
                  ? `₦${formatAmount(HouseHoldGetQuoteRes.totalSumInsured)}`
                  : ""}
              </span>
            </div>
            <div className={styles["get-quote-card"]}>
              <p className={styles["get-quote-header"]}>Quote</p>
              {/* <span>Your Payable Premium Will Appear Here</span> */}
              <span className={styles["get-quote-card-p"]}>Annual Payable Premium</span>
              <div className={styles["get-quote-card-span"]}>
                {HouseHoldGetQuoteRes.totalPremium
                  ? `₦${formatAmount(HouseHoldGetQuoteRes.totalPremium)}`
                  : ""}
              </div>
            </div>
          </div>
        )}

        <div className={styles["container-bottom-butt"]}>
          <span onClick={() => goBack()} role="button">
            Back
          </span>
          {HouseHoldGetQuoteRes !== undefined && (
          <div
            className={styles["continue-butt"]}
            role="button"
            onClick={() => goNext()}
          >
            <span>Save & Continue</span>
          </div>
          )}
        </div>
        
    </>
  );
};

export default GetAQuote;
