import Layout from "../../components/Layout";
import NavHeader from "../../components/NavHeader";
import ScrollToTop from "../../components/ScrollToTop";
import SessionCheck from "../../components/SessionCheck";
import Solar from "../../sections/Solar/index"

const Index = () => {
  return (
    <Layout>
      <ScrollToTop />
      <SessionCheck shouldRedirect />
      <NavHeader pageTitle="Solar Financing" routeName="Solar Financing" />
      <Solar/>
    </Layout>
  );
};

export default Index;