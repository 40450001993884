import useSwr, { useSWRConfig } from 'swr'
import {
  UserForgetPasswordRequest,
  UserRegistrationRequest,
  UserRegistrationResponse,
  UserResetPassowrdRequest,
  UserForgetPasswordResponse,
  UserResetPasswordResponse,
  GetUserResponse,
  UserChangePasswordRequest,
  UserChangePasswordResponse,
} from '../types/User'
// import axios from 'axios'
import Axios, { AxiosError, AxiosResponse } from 'axios'
import { GeneralOperationResult } from '../types/GeneralResponse'
import { GetWalletDetailsResponse } from '../types/Wallet'
import api from '../axios'

const baseUrl = process.env.REACT_APP_BASE_URL || 'https://api.homebuddy.ng'
const token = localStorage.getItem('token')

interface RegistrationResult {
  data?: UserRegistrationResponse
  error?: any
}

interface ResetPasswordResult {
  data?: UserResetPasswordResponse
  error?: any
}

interface ForgotPasswordResult {
  data?: UserForgetPasswordResponse
  error?: any
}

interface ConfirmEmail {
  succeeded: boolean
  message: string
  errors: string
  data: string
  meta: string
}

export const registerUser = (request: UserRegistrationRequest | any) => {
  const willRegisterUser = new Promise<RegistrationResult>((resolve) => {
    Axios.post(baseUrl + '/api/Account/register', request, {
      headers: {
        'Content-Type': 'application/json',
      },
    })
      .then((response: AxiosResponse<UserRegistrationResponse>) => {
        resolve({ data: response.data })
      })
      .catch((err: AxiosError | any) => {
        resolve({ error: err })
      })
  })

  return willRegisterUser
}

export const resetUserPassword = (request: UserResetPassowrdRequest) => {
  const willResetUserPassword = new Promise<ResetPasswordResult>((resolve) => {
    Axios.post(baseUrl + '/api/Account/reset-password', request)
      .then((response: AxiosResponse<UserResetPasswordResponse>) => {
        resolve({ data: response.data })
      })
      .catch((err: AxiosError | any) => {
        resolve({ error: err })
      })
  })

  return willResetUserPassword
}

export const forgetUserPassword = (
  request: UserForgetPasswordRequest,
  token?: string
) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }
  const willForgetUserPassword = new Promise<ForgotPasswordResult>(
    (resolve) => {
      Axios.post(baseUrl + '/api/Account/forgot-password', request, config)
        .then((response: AxiosResponse<UserForgetPasswordResponse>) => {
          resolve({ data: response.data })
        })
        .catch((err: AxiosError | any) => {
          resolve({ error: err })
        })
    }
  )
  return willForgetUserPassword
}

export const getUser = async (email?: string, token?: string) => {
  const config = {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  }

  const { data } = await Axios.get(`${baseUrl}/api/Account/${email}`, config)
  return data
}
// export const getUser = (email?: string, token?: string) => {
//     const config = {
//         headers: {
//             Authorization: `Bearer ${token}`
//         }
//     }

//     const user = new Promise<any>((resolve)=> {
//         Axios.get(baseUrl + `/api/Account/${email}`, config)
//         .then((response:AxiosResponse<GetUserResponse>)=> {resolve({data: response.data})
//         }).catch((err: AxiosError | any)=>{
//             resolve({error: err})
//         })
//     })
//     console.log(user)
//     return user;
// }

export const useGetUser = (email?: string, token?: string) => {
  const fetcher = (url: string) => {
    return Axios.get(baseUrl + url, {
      headers: { Authorization: `Bearer ${token}` },
    }).then((response: AxiosResponse<GetUserResponse>) => response.data)
  }

  const { data, error } = useSwr(`/api/Account/${email}`, fetcher)

  return {
    user: data?.data,
    isLoading: !error && !data,
    isError: false,
  }
}

export const updateUserDetails = (userDetails: any, email: string | null) => {
  const willUpdateUserDetails = new Promise<GeneralOperationResult<any>>(
    (resolve) => {
      const config = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      }
      api.post(`/api/Account/UpdateUser${email}`, userDetails, config)
        .then((response: AxiosResponse<any>) => {
          resolve({ data: response.data })
        })
        .catch((err: AxiosError) => {
          resolve({ error: err })
        })
    }
  )
  return willUpdateUserDetails
}

export const confirmEmail = async (
  useRId: string,
  userCode: string
): Promise<ConfirmEmail> => {
  const { data } = await Axios.get(
    `${baseUrl}/api/Account/confirm-email?userId=${useRId}&code=${userCode}`
  )
  return data
}

export const ChangePassword = async (
request: UserChangePasswordRequest, token:string
) => {
  const willChangePassword = new Promise<GeneralOperationResult>((resolve) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    api.post('/api/Account/change-password', request, config)
      .then((response: AxiosResponse<UserChangePasswordResponse>) => {
        resolve({ data: response.data })
      })
      .catch((err: AxiosError | any) => {
        resolve({ error: err })
      })
  })

  return willChangePassword
}

export const DeleteAccountApi = async (
confirm: boolean, token:string
) => {
  const willDeleteAccount = new Promise<GeneralOperationResult>((resolve) => {
    const config = {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
    api.post(`/api/Account/DeleteUser?confirm=${confirm}`, {}, config)
      .then((response: AxiosResponse<any>) => {
        resolve({ data: response.data })
      })
      .catch((err: AxiosError | any) => {
        resolve({ error: err })
      })
  })

  return willDeleteAccount
}