import React, { useContext, useEffect, useState } from "react";
import { Property } from "../../types/Property";
import { PaginationParamsPersonalizedProperties } from "../../types/propertyType";
import Pagination from "@mui/material/Pagination";
import LoadingContext from "../../contexts/LoadingContext";
import { getPropertiesByAgentId } from "../../swr/properties";
import ToastContext from "../../contexts/ToastContext";
import styles from "./agentProperties.module.scss"
import PropertyCard from "../../components/PropertyCard";
import Stack from "@mui/system/Stack";

const Index = () => {
  const { openError } = useContext(ToastContext);
  const { setLoading } = useContext(LoadingContext);
  const [paginationParams, setPaginationParams] = useState({
    PageNumber: 1,
    PageSize: 10,
  });
  const token = localStorage.getItem("token") as string;
  const listerId = localStorage.getItem("listerId") as string;
  const [properties, setProperties] = useState<Property[]>([]);
  const [pageParams, setPageParams] = useState(
    {} as PaginationParamsPersonalizedProperties
  );

  const GetPropertiesByAgent = async () => {
    setLoading(true);
    const { data, error } = await getPropertiesByAgentId(
      {
        agentId: listerId,
        pageSize: paginationParams.PageSize,
        pageNumber: paginationParams.PageNumber,
      },
      token
    );

    if(data) {
      console.log(data, 'get Listings +======++++====');
      console.log(properties);
      setLoading(false)
      setProperties(data.data)
    }

    if (data && data.pageMeta) {
      setLoading(false);
      setPageParams(data.pageMeta);
      return;
    }

    if (error) {
      openError(
        error.response.data.Message
          ? error.response.data.Message
          : "Unable to fetch saved properties"
      );
      setLoading(false);
      return;
    }

    openError("Unable to fetch similar properties");
    setLoading(false);
    return;
  };

  const handlePageChange = (
    event: React.ChangeEvent<unknown>,
    newPage: number
  ) => {
    setPaginationParams((prev) => ({ ...prev, PageNumber: newPage }));
  };

  const returnProperties = (mortgagedProperties: Property[]) => {
    return mortgagedProperties.map((prop) => (
      <div className={styles.griditem}>
        <PropertyCard property={prop} key={prop.title} />
      </div>
    ));
  };

  useEffect(() => {
    // GetPropertiesByAgent();
    if (paginationParams.PageNumber > 0) {
      GetPropertiesByAgent();
    }
  }, [paginationParams]);

  console.log(listerId);
  

  return (
    <div className={styles.overallContainer}>
      {properties && properties.length > 0 ? (
        <>
          <div className={styles.containerI}>
            {returnProperties(properties)}
          </div>
          <div className={styles.paginationDiv}>
            {!(
              paginationParams.PageNumber == 1 &&
              properties &&
              properties.length < paginationParams.PageSize
            ) && (
              <Stack
                spacing={2}
                sx={{ marginBottom: "30px", alignItems: "center" }}
              >
                <Pagination
                  count={pageParams && pageParams.totalPages}
                  page={paginationParams.PageNumber}
                  onChange={handlePageChange}
                  size={"large"}
                  variant="outlined"
                  color="standard"
                />
              </Stack>
            )}
          </div>
        </>
      ) : (
        <div className={styles.noListing}>
          <span className={styles.fonts}>No Properties Available</span>
        </div>
      )}
    </div>
  );
};

export default Index;
