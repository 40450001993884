import React, { useState } from "react";
import solarBg from "../../assets/solar-bg.png";
import styles from "./solar.module.scss";
import solarbg2 from "../../assets/solar-bg2.png";
import TextInput from "../../components/FormElements/TextInput";
import reportDropdown from "../../assets/reportdropdown.svg";
import ModalLayout from "../../components/ModalLayout";
import PopUp from "../../components/Insurance/PopUp";
import plus from "../../assets/plus.svg";
import cancel from "../../assets/cancel.svg";
import {
  Currencies,
  CurrencyArray,
  SolarFinanceRequest,
  WorkTypeArray,
  WorkTypes,
} from "../../types/Solar";
import { applySolarFinance } from "../../swr/solar";
import { toast } from "react-toastify";

const Index = () => {
  const [showCurrencies, setShowCurrencies] = useState(false);
  const [showEarners, setShowEarners] = useState(false);
  const [isVendorsDisplayed, setIsVendorsDisplayed] = useState(false);
  const [solarFinance, setSolarFinance] = useState<SolarFinanceRequest>({
    fullName: "",
    phoneNumber: "",
    email: "",
    accessAcount: "",
    closestBranch: "",
    workType: null,
    monthlyIncome: 0,
    currency: null,
  });
  const token = localStorage.getItem("token") as string;

  const handleInputChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    setSolarFinance({ ...solarFinance, [e.target.name]: e.target.value });
  };

  const handleSubmitForm = async () => {
    const {
      fullName,
      phoneNumber,
      email,
      accessAcount,
      closestBranch,
      workType,
      monthlyIncome,
      currency,
    } = solarFinance;

    // Check for empty fields
    if (
      !fullName ||
      !phoneNumber ||
      !email ||
      !accessAcount ||
      !closestBranch ||
      workType === null ||
      monthlyIncome === 0 ||
      currency === null
    ) {
      toast.error("Fill all fields");
      return;
    }

    const formData = {
      fullName: solarFinance.fullName,
      phoneNumber: solarFinance.phoneNumber,
      email: solarFinance.email,
      accessAcount: solarFinance.accessAcount,
      closestBranch: solarFinance.closestBranch,
      workType: solarFinance.workType,
      monthlyIncome: solarFinance.monthlyIncome,
      currency: solarFinance.currency,
    };

    console.log(formData, 'MY----FORMM----DATAAAAA');
    

    const { data, error } = await applySolarFinance(formData, token);
    if (data) {
      console.log("Form submitted successfully");
      toast.success("Form submitted successfully");
      console.log(data.message);
      setSolarFinance({
        fullName: "",
        phoneNumber: "",
        email: "",
        accessAcount: "",
        closestBranch: "",
        workType: null,
        monthlyIncome: 0,
        currency: null,
      });
    }
    if (error) {
      console.error("Error submitting report", error.message);
      toast.error("Error submitting report");
    }
  };

  return (
    <div>
      <div className={styles.img_container}>
        <img src={solarBg} alt="" className={styles.image} />
        <div className={styles.centered}>
          <span className={styles.lg_text}>
            Switch to Solar, Brighten Up Your Dream Home Sustainably.
          </span>
          <span className={styles.sm_text}>
            Experience the freedom of renewable energy and reduced utility bills
            with our flexible financing options.
          </span>
          {/* <button className={styles.apply_btn}>Apply Now</button> */}
        </div>
      </div>

      {/* Cards and Form */}
      <div className={styles.form_card_layout}>
        {/* Card Section */}
        <div className={styles.card_list}>
          <div className={styles.white_card}>
            <span className={styles.header}>Say goodbye to power issues</span>
            <span className={styles.subtitle}>
              The Switch to solar lease product aims to leverage the rapidly
              improving economics of solar and storage technologies to enable
              households, commercial and industrial clients to adopt a cleaner
              and more reliable source of electricty through solar photovoltaic
              (pv) and batteries by offering affordable financing opportunity.
            </span>
          </div>
          <div className={styles.light_green_card}>
            <span className={styles.header}>Features</span>
            <ul>
              <li>
                Access up to N50m for the financing of Solar Photovoltaic (PV),
                Batteries & Inverters along with installation
              </li>
              <li>Competitive interest rate</li>
              <li>Flexible repayment period up to 48 months</li>
              <li>As low as 10% equity contribution on invoice value.</li>
            </ul>
          </div>
          <div className={styles.white_card}>
            <span className={styles.header}>
              Documentation Requirements for Solaried Employees
            </span>
            <ul>
              <li>
                Pro forma invoice from approved vendors addressed Access
                Bank/Customer's name
              </li>
              <li>Completed lease Application form</li>
              <li>Copy of employment ID card</li>
              <li>Blank transfer of ownership</li>
            </ul>
          </div>
          <div className={styles.rel_view}>
            <div className={styles.basic_green_card}>
              <span className={styles.header}>
                Documentation Requirements for Businesses
              </span>
              <ul>
                <li>Completed lease Application form</li>
                <li>
                  Personal guarantee and statement of net worth of a credible
                  guarantor
                </li>
                <li>Evidence of company registration documents with CAC</li>
                <li>
                  Simple resolution - For Enterprises & Board Resolution - For
                  incorporated companies
                </li>
                <li>
                  3 years Audited Financial Statement or 2 years Audited
                  Financial Statements and 1 - year Management account (for
                  facility of 2om and above).
                </li>
              </ul>
            </div>
            <img src={solarbg2} alt="" className={styles.overlapped_image} />
          </div>
        </div>
        <div className={styles.form_layout}>
          <span className={styles.form_header}>INTEREST FORM</span>
          <span>Fields marked with asteriks(*) are very important!</span>
          <div className={styles.input_divs}>
            <TextInput
              type="text"
              name="fullName"
              placeholder="Full Name*"
              required={true}
              onChange={handleInputChange}
              value={solarFinance.fullName}
            />
            <TextInput
              type="number"
              name="phoneNumber"
              placeholder="Phone Number"
              required={true}
              onChange={handleInputChange}
              value={solarFinance.phoneNumber}
              style={{marginBottom: 30}}
            />
            <TextInput
              type="email"
              name="email"
              placeholder="Email Address"
              required={true}
              onChange={handleInputChange}
              value={solarFinance.email}
            />
            <TextInput
              type="number"
              name="accessAcount"
              placeholder="Access Bank Account Number*"
              required={true}
              onChange={handleInputChange}
              value={solarFinance.accessAcount}
              style={{marginBottom: 30}}
            />
            <TextInput
              type="text"
              name="closestBranch"
              placeholder="Closest Branch (Non Access Bank Customer)"
              required={true}
              onChange={handleInputChange}
              value={solarFinance.closestBranch}
            />
            <div className={styles.dropdown_view}>
              <input
                type="text"
                placeholder="Are you a salary earner or business owner?"
                name="workType"
                readOnly
                value={solarFinance.workType != null ? WorkTypes[solarFinance.workType] : ""}
              />
              <img
                src={reportDropdown}
                alt=""
                onClick={() => setShowEarners(!showEarners)}
              />
            </div>
            <TextInput
              type="number"
              name="monthlyIncome"
              placeholder="What is your monthly income?"
              required={true}
              onChange={handleInputChange}
              value={solarFinance.monthlyIncome}
            />

            <div className={styles.dropdown_view} style={{ marginTop: 30 }}>
              <input
                type="text"
                placeholder="What currency do you earn in?"
                name="currency"
                readOnly
                value={solarFinance.currency != null ? Currencies[solarFinance.currency] : ""}
              />
              <img
                src={reportDropdown}
                alt=""
                onClick={() => setShowCurrencies(!showCurrencies)}
              />
            </div>
          </div>
          <button onClick={handleSubmitForm} className={styles.submit_btn}>
            Submit Now
          </button>
        </div>
      </div>

      <div className={styles.list_border}>
        <div className={styles.list_container}>
          <div className={styles.list_header}>
            <span className={styles.list_title}>List of Vendors</span>
            <div
              className={styles.icon_style}
              onClick={() => setIsVendorsDisplayed(!isVendorsDisplayed)}
            >
              <img src={isVendorsDisplayed ? cancel : plus} alt="" />
            </div>
          </div>
          {isVendorsDisplayed && (
            <div className={styles.displayed_vendors}>
              <span>Infranegy Nigeria Ltd.</span>
              <span>Digitspot Solutions Ltd.</span>
              <span>Vertical Energy and Networks Ltd.</span>
              <span>Nta-star TV Network Limited</span>
              <span>Movi Power Systems Ltd.</span>
              <span>Olaade Innovative Concepts Ltd.</span>
              <span>Artme Ventures Limited</span>
              <span>Solar Plus Total Energy Solutions</span>
              <span>Sims Nig Ltd.</span>
              <span>Cloud Energy Photoelectric Ltd.</span>
              <span>Eco-power Resources Ltd.</span>
              <span>Artu Energy Limited</span>
              <span>Nayo Tropical Technology Ltd.</span>
              <span>Solargaps Ltd.</span>
              <span>Auxano Solar Nig Ltd.</span>
              <span>Wavelength Integrated</span>
              <span>Swift Technology Solution</span>
            </div>
          )}
        </div>
      </div>

      <ModalLayout modalOpen={showEarners === true}>
        <PopUp
          options={WorkTypeArray}
          click={(selectedOption: string) => {
            const workTypeIndex =
              WorkTypes[selectedOption as keyof typeof WorkTypes];
            setSolarFinance({ ...solarFinance, workType: workTypeIndex });
            setShowEarners(false);
          }}
        />
      </ModalLayout>

      <ModalLayout modalOpen={showCurrencies === true}>
        <PopUp
          options={CurrencyArray}
          click={(selectedOption: string) => {
            const currIndex =
              Currencies[selectedOption as keyof typeof Currencies];
            setSolarFinance({ ...solarFinance, currency: currIndex });
            setShowCurrencies(false);
          }}
        />
      </ModalLayout>
    </div>
  );
};

export default Index;
