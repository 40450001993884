import Axios, { AxiosError, AxiosResponse } from "axios";
import { ReportListingReq } from "../types/Report";

const testBaseUrl = "https://api-sandbox.homebuddy.ng";

export const reportProperty = (req: ReportListingReq, token: string) => {
  console.log("Request Payload:", req); // Log the request payload
  const willReportListing = new Promise<any>((resolve) => {
    Axios.post(testBaseUrl + "/api/Reports/ReportProperty", req, {
      headers: {
        Authorization: `Bearer ${token}`,
      },
    })
      .then((response: AxiosResponse<any>) => {
        resolve({ data: response.data });
      })
      .catch((err: AxiosError | any) => {
        resolve({ error: err });
      });
  });
  return willReportListing;
};
