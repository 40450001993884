import { NavMenuItem } from "../components/NavigationBar";

const otherServices: NavMenuItem[] = [
    { label: 'Utility Bill Payment', href: '/utility-bills' },
]

const servicesSubItems = [
    { label: 'Save For Rent', href: '/save-for-rent' },
    { label: 'UtilityBills', href: '/utility-bills' },
    { label: 'Mortgage', href: '/mortgage' },
    { label: 'Insurance', href: '/insurance' },
    {label: 'Solar Financing', href: '/solar-financing'},
    {label: 'Smart Home Automation', href: '/smart-homes'}
]

export const menuItems: NavMenuItem[] = [
    { label: ' About Us', href: '/about-us' },
    { label: 'Listings', href: '/properties' },
    // { label: 'Marketplace', href: '/market-place' },
     // { label: 'Marketplace', href: '#' },
    { label: 'Our Services', href: '', subItems: servicesSubItems },
    { label: 'Contact', href: '/contact' }, 
]

// export const menuItems: NavMenuItem[] = [
//     { label: ' About Us', href: '/about-us' },
//     { label: 'Listings', href: '/properties' },
//     // { label: 'Marketplace', href: '/market-place' },
//     { label: 'Save For Rent', href: '/save-for-rent' },
//     // { label: 'Marketplace', href: '#' },
//     { label: 'UtilityBills', href: '/utility-bills' },
//     { label: 'Mortgage', href: '/mortgage' },
//     // { label: 'Mortgage', href: '/mortgage' },
//     { label: 'Contact', href: '/contact' },
// ]

export const menuItemsII: NavMenuItem[] = [
    { label: 'Listings', href: '/properties', },
    // { label: 'Marketplace', href: '/market-place' },
    { label: 'Save For Rent', href: '/save-for-rent' },
    { label: 'My Account', href: '/account' },
    { label: 'My Listings', href: '/mylistings' },
    { label: 'Admin Dashboard', href: '/dashboardad' },
    { label: 'Pension Applications', href: '/viewpensions' },
    { label: 'Mortgage Applications', href: '/viewmortgage' },
    { label: 'Rent Insurance Applications', href: '/viewrentinsurance' },
    { label: 'UtilityBills', href: '/utility-bills' },
    { label: 'Mortgage', href: '/mortgage' },
    { label: 'Contact', href: '/contact' },
    { label: 'Add Listing', href: '/add-listing' },
    { label: 'Insurance', href: '/insurance' },
    { label: 'Sign In', href: '/signin' },
    { label: 'Sign Up', href: '/signup' },
    { label: 'Sign Out', href: '/logout' },
]