import React from "react";
import styles from "./homeOwners.module.scss";
import firstLayerImg from "../../../assets/african-american.jpg";
import firstLayerImgTop from "../../../assets/rent-first-top.jpg";
import firstLayerImgBottom from "../../../assets/rent-first-bottom.jpg";
import rentSecond1 from "../../../assets/rent-second-img1.jpg";
import rentSecond2 from "../../../assets/rent-second-img2.jpg";
import rentSecond3 from "../../../assets/rent-second-img3.jpg";
import { useHistory } from "react-router";

type Coverage = {
  img: any;
  head: string;
  text: string[];
};

type Benefit = {
  img: any;
  text: string;
};

const coverageArr: Coverage[] = [
  {
    img: rentSecond1,
    head: "Legal Expenses",
    text: [
      "Fire, Lightning, Explosion, Aircraft damage",
      "Bursting or Overflowing of Water Tanks, Apparatus or Pipes,",
      "Impact Damage by Road Vehicles/Animals not belonging to or under the control of the insured.",
    ],
  },
  {
    img: rentSecond2,
    head: "Bushfire, Riot and Strike",
    text: [
      "Damage to premises during a burglary.",
      "Legal liabilities to third parties other than family members.",
      "Bushfire, Riot, and Strike, Malicious damage, Tornado, Flood, Earthquake.",
    ],
  },
  {
    img: rentSecond3,
    head: "Alternative Habitation Cost",
    text: [
      "Theft of valuables, jewelry, and cash kept in safe.",
      "Theft of household items (i.e. furniture and other belongings)",
      "Covers the rent you would lose, as a landlord, if your property becomes uninhabitable due to an insured event (e.g. a fire or flood) and your tenants are forced to move out.",
    ],
  },
];

const benefitsArr: Benefit[] = [];

const returnImgBg = (num: number) => {
  if (num === 0) {
    return "#FFFEFA";
  } else if (num === 1) {
    return "#FDF8F6";
  } else {
    return "#F6FDF6";
  }
};

const returnTxtBg = (num: number) => {
  if (num === 0) {
    return "#FDF5D8";
  } else if (num === 1) {
    return "#FAE9E5";
  } else {
    return "#E5FAE5";
  }
};

const HomeOwners = () => {
  const history = useHistory();
  const goToApplication = () => {
    history.push("/insurance/requestandbuy");
  };
  return (
    <div className={styles["home-inner"]}>
      <div className={styles["first-layer"]}>
        <div className={styles["first-layer-texts"]}>
          <span className={styles["first-layer-texts-para"]}>
            Homeowners Insurance
          </span>

          <span className={styles["first-layer-texts-span"]}>
            This policy safeguards homeowners from losses or damage caused by
            fire and related perils. it additionally covers building damage
            resulting from attempted theft.
          </span>

          <span className={styles["first-layer-texts-span"]}>
            Enjoy peace of mind knowing your home is protected against
            unforeseen events, ensuring financial support in the face of
            emergencies.
          </span>
        </div>

        <div className={styles["first-layer-img"]}>
          <img src={firstLayerImg} className={styles["first-layer-image1"]} />
          <div className={styles["first-layer-image-top"]}>
            <img
              src={firstLayerImgTop}
              className={styles["first-layer-image"]}
            />
          </div>

          <div className={styles["first-layer-image-bottom"]}>
            <img
              src={firstLayerImgBottom}
              className={styles["first-layer-image"]}
            />
          </div>
        </div>
      </div>

      <div className={styles["coverage-cont"]}>
        <span className={styles["rent-header"]}>What It Covers</span>

        {coverageArr.map((cover, index) => (
          <div className={styles["coverage"]} key={cover.head}>
            <div
              className={styles["coverage-img-cont"]}
              style={{ backgroundColor: returnImgBg(index) }}
            >
              <img
                src={cover.img}
                alt={cover.head}
                className={styles["coverage-img"]}
              />
            </div>
            <div
              className={styles["coverage-cont-text"]}
              style={{ backgroundColor: returnTxtBg(index) }}
            >
              <div className={styles["coverage-li-container"]}>
                <div className={styles["coverage-cont-text-inner"]}>
                  {cover.text.map((item, index) => (
                    <div
                      className={styles["coverage-cont-text-inner-each"]}
                      key={index}
                    >
                      <div className={styles["coverage-dot"]}></div>
                      <div
                        className={styles["coverage-cont-text-inner-each-li"]}
                      >
                        {item}
                      </div>
                    </div>
                  ))}
                </div>
              </div>
              {/* <span className={styles["coverage-cont-text-p"]}>
                {cover.head}
              </span>
              <span className={styles["coverage-cont-text-span"]}>
                {cover.text}
              </span> */}
            </div>
          </div>
        ))}

        <div
          className={styles["black-but"]}
          role="button"
          onClick={() => goToApplication()}
        >
          Request & Buy
        </div>
      </div>

      {/* <div className={styles["benefits"]}>
        <div className={styles["benefits-header"]}>Benefits</div>
        <div className={styles["benefits-box-cont"]}>
          <div className={styles["vertical-cross"]}></div>
          <div className={styles["horizontal-cross"]}></div>
          {benefitsArr.map((benefit) => (
            <div
              className={styles["benefits-box"]}
              key={benefit.text.slice(0, 5)}
            >
              <img
                src={benefit.img}
                alt={benefit.text.split(" ")[0]}
                className={styles["benefits-img"]}
              />
              <span className={styles["benefits-text"]}>{benefit.text}</span>
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
};

export default HomeOwners;
